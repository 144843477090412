import { formHeader } from '../helpers/index.js';
import { apiHost } from '../config.js';

export const createBlog = (requestParams) => {
    return fetch(apiHost + '/v1/blogs', {
        method: 'POST',
        body: requestParams,
        headers: formHeader,
      }).then(async (response) => {
        if (response.ok) {
          try {
            return response.json();
          } catch (err) {
            return true;
          }
        }
        const errorMessage = [response.status, await response.json()];
        throw errorMessage;
      });
};
