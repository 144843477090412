import React, { useEffect, useState } from 'react';
// import other from 'assets/media/other.jpg';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';

export const Others = (props) => {
    const [errorPage, setErrorPage] = useState(true);
    const [data, setData] = useState([]);
    const requestParams = [
        { name: 'other_resource', value: 'other_resource' }
    ];

    useEffect(() => {
        fetchArticleByType(requestParams, false, 10).then(({ data }) => {
            setData(data);
            setErrorPage(false);
        }).catch(() => setErrorPage(true));
    }, []);

    if (errorPage) {
        <div></div>;
    }

    return (
        <>
            {data?.[0]?.id &&
                <div className="w-full mt-10 bg-[#231F1F] pb-10" id="others">
                    <div className="flex flex-col sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto text-white py-6 gap-10">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-white">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">other</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}>See All <span className="uppercase">other</span></p></div>
                        </div>
                        <div className="flex-1 xs:flex-col sm:flex-col flex items-center lg:flex-row-reverse md:flex-col xs:gap-10 sm:gap-14 md:gap-14 lg:gap-14 2xl:gap-16">
                            <div className="lg:flex-1">
                                <img className="w-full h-full" src={data[0]?.banner_image?.url || DummyImage} alt="..." />
                            </div>
                            <div className="flex-1 flex flex-col xs:gap-8 sm:gap-10 md:gap-10 lg:gap-10 2xl:gap-12">
                                <div className="text-3xl font-medium xs:w-full sm:w-[80%]"><h1>{data[0]?.title}</h1></div>
                                <div className="flex flex-col gap-4">
                                    <p className='text-justify'>{data[0]?.short_description}</p>
                                    {/* <p>Oman is a nation with a legendary passion for the automobile that drives an unflagging appetite for the world`&apos;`s leading auto brands, reflecting the diverse interests and discerning tastes of the country`&apos;`s motoring public. A vibrant domestic industry has grown in its wake, fuelled by national infatuation with cars and vehicles of all kinds.</p>
                             <p>Lorem ipsum dolor sit amet. Quo odio sint sed animi omnis aut quae omnis est repudiandae quisquam est dolorem voluptas!</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
