import React from 'react';
import { login } from 'api/authentication';
import { Component } from 'react/cjs/react.production.min';
import { setCookie } from 'helpers';
// import zubairLogo from 'assets/media/zubair-logo.png';
import { RightSide } from './rightSideCard';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                phone: '',
                password: ''
            },
            submitBtnEnable: true,
            signup: false,
            otpField: false,
            iconClassName: 'far fa-eye-slash',
            errorMessage: false,
            passwordType: 'password',
            btnEnable: true
        };
    }

    onFinish = () => {
        const { data } = this.state;
        if (!data.phone || !data.password) {
            this.setState({ errorMessage: 'Ensure the above fields are filled', errorStatus: false });
            return;
        }
        this.setState({ btnEnable: false });
        login({ ...data }).then(({ data }) => {
            setCookie('auth_token', data?.auth_token, 30);
            setCookie('user_id', data.id, 30);
            setCookie('name', data.name, 30);
            window.location.href = '/home';
        }).catch(error => {
            this.setState({ errorMessage: error[0] === 401 ? 'Invalid GSM Number or Password' : error[1].message || (error[1].data && error[1].data[0]), btnEnable: true });
        });
    };

    onChange = (e) => {
        const { data } = this.state;
        data[e.target.name] = e.target.value;
        this.setState({ data });
    }

    onVerified = (e, value) => {
        if (value === 'password') {
            this.setState({ setPassword: true });
        } else if (value === 'login') {
            this.setState({ setPassword: false, signup: false });
        } else {
            this.setState({ otpField: true });
        }
    }

    toggleIconChange = () => {
        let { passwordType, iconClassName } = this.state;
        passwordType = passwordType === 'password' ? 'text' : 'password';
        iconClassName = iconClassName === 'far fa-eye-slash' ? 'far fa-eye' : 'far fa-eye-slash';
        this.setState({ passwordType, iconClassName });
    }

    handleChange = () => {
        this.props.history.push({ pathname: '/signup' });
        this.setState({ signup: true });
    }

    render() {
        const { passwordType, iconClassName, errorMessage, btnEnable } = this.state;
        const { onFinish, onChange, handleChange, toggleIconChange } = this;
        return (
            <div className="login-page" >
                <div className="login-box">
                    <RightSide />
                    <from
                        id="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onSubmit={(e) => onFinish(e)}
                    >
                        <h1 className="form-title">Welcome back</h1>
                        <p>Login to the Portal</p>

                        <input id='login-form_username' name='phone' placeholder="GSM Number" onChange={(e) => onChange(e)} />

                        <input id='login-form_username' name='password' placeholder="Password" onKeyPress={(e) => { if (e.which === 13) onFinish(); }}
                            type={passwordType} onChange={(e) => onChange(e)} />
                        <i className={iconClassName} id="togglePassword" style={{
                            marginLeft: '-30px', cursor: 'pointer'
                        }} onClick={() => { toggleIconChange(); }} />
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='checkbox'>
                                <input type="checkbox" id="remember" name="remember" value="remember" />
                                <label htmlFor="remember">Remember me</label>
                            </div>
                            <p style={{ cursor: 'pointer' }} onClick={(e) => this.props.history.push('/forget-password')}>Forgot password</p>
                        </div> */}
                        {errorMessage && <div style={{ textAlign: 'center', color: 'red', padding: '5px' }}>{errorMessage} </div>}

                        <button type="primary" disabled={!btnEnable} htmlType="submit" style={{ backgroundColor: '#0c5ef1' }} className="login-form-button" onClick={onFinish}>
                            LOGIN
                        </button>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <p>
                                Don &#39; t have a account? <span onClick={handleChange} id='signup' >Register</span>
                            </p>
                        </div>
                    </from>
                </div>
            </div>
        );
    }
};
export default Login;
