import React, { Component } from 'react';
import { isSameDay } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { Calendar } from 'react-nice-dates';
import moment from 'moment';
import 'react-nice-dates/build/style.css';
import 'assets/css/new_date.css';

class CalendarExample extends Component {
    constructor(props) {
        super(props);
        this.modifiers = {
            selected: (date) => (
                this.props.temp.some(selectedDate => (
                    isSameDay(new Date(selectedDate), new Date(date))
                ))
            )
        };
        this.state = {
            selectedDates: this.props.temp || []
        };
    }

    handleDayClick = date => {
        this.props.selectedDate(moment(date).format('MM/DD/YYYY'));
    };

    render() {
        return (
            <Calendar
                onDayClick={this.handleDayClick}
                modifiers={this.modifiers}
                locale={enGB} />
        );
    }
} export default CalendarExample;
