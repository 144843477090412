import React, { useState, useEffect } from 'react';
import logo from 'assets/media/zag-logo-2.svg';
import { Link } from 'react-router-dom';
import 'assets/css/common.css';
import { deleteAllCookie, getCookie, setCookie } from 'helpers';
import { logout } from 'api/authentication';
import person from 'assets/media/person.svg';
import { fetchUserData } from 'api/user';

const Header = ({ loader }) => {
    const [mobile, setMobile] = useState(false);
    const [active, setActive] = useState(null);
    const [nav, setNav] = useState(false);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        fetchProfileData();

        const handleDocumentClick = (event) => {
            const hiddenDiv = document.getElementById('mobile');
            if (hiddenDiv && event.target.id !== 'mobile') {
                hiddenDiv.style.display = 'none';
            }
        };

        window.addEventListener('click', handleDocumentClick);

        return () => {
            window.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    useEffect(() => {
        fetchProfileData();
    }, [getCookie('userProfile')]);

    useEffect(() => {
        setActive(window.location.pathname.split('/')[1]);
    }, []);

    const fetchProfileData = () => {
        fetchUserData()
            .then(({ data }) => {
                setCookie('userProfile', data?.image_url);
                setUserData(data);
            })
            .catch(error => {
                console.log('error', error);
            });
    };

    const logOut = () => {
        logout()
            .then(response => {
                console.log(response.message);
                deleteAllCookie();
            })
            .catch(error => {
                console.log('error', error);
            });
    };

    const handleopen = () => {
        setNav(!nav);
    };

    if (loader) {
        return null;
    }

    console.log(userData, 'popopooooopopopoo');

    return (
        <div className="w-full sticky bg-white top-0 z-[99999999999]">
            <div className="xs:px-4 xs:w-auto xs:justify-between xs:items-center sm:container sm:mx-auto xs:px-4 md:px-4 lg:px-0 sm:justify-between sm:items-center flex">
                <div className="xs:flex-1 lg:flex-[1_1_0%] xl:flex-[1_1_0%] 2xl:flex-[1_1_0%] xs:pt-0 md:pt-0 hover:cursor-pointer">
                    <Link to="/home">
                        <img src={logo} className="sm:w-[30%] md:w-[30%] lg:w-[60%] xl:w-[60%] 2xl:w-[50%]" alt="logo" />
                    </Link>
                </div>
                <div className="xs:hidden sm:hidden lg:flex lg:flex-[3_1_0%] xl:flex-[3_1_0%] 2xl:flex-[3_1_0%] flex-1 uppercase text-black bg-white border-b-[12px] border-[#D42234]">
                    <ul className="flex-1 inline-flex lg:text-[0.77rem] xl:text-[.86rem] 2xl:text-[1rem] header_active" style={{ fontFamily: 'Futura' }}>
                        <Link to="/leadership" className={`pt-12 pb-9 hover:cursor-pointer lg:px-[0.64rem] xl:px-[1.12rem] 2xl:px-[1.48rem] hover:bg-[#6C6D70] hover:!text-white duration-500 ${active === 'leadership' ? 'theme-red ' : ''}`}>
                            <li> القيادة <br /> leadership</li>
                        </Link>
                        <Link to="/celebrating-people" className={`pt-12 pb-9 hover:cursor-pointer lg:px-[0.64rem] xl:px-[1.12rem] 2xl:px-[1.48rem] hover:bg-[#6C6D70] hover:!text-white duration-500 ${active === 'celebrating-people' ? 'theme-red' : ''}`}>
                            <li>نحتفل معكم<br /> celebrating people</li>
                        </Link>
                        <Link to="/hr-brief" className={`pt-12 pb-9 hover:cursor-pointer lg:px-[0.64rem] xl:px-[1.12rem] 2xl:px-[1.48rem] hover:bg-[#6C6D70] hover:!text-white duration-500 ${active === 'hr-brief' ? 'theme-red' : ''}`}>
                            <li> نشرات الموارد البشرية<br />hr briefs</li>
                        </Link>
                        <Link to="/news-events" className={`pt-12 pb-9 hover:cursor-pointer lg:px-[0.64rem] xl:px-[1.12rem] 2xl:px-[1.48rem] hover:bg-[#6C6D70] hover:!text-white duration-500 ${active === 'news-events' ? 'theme-red' : ''}`}>
                            <li> الاحداث و الاخبار<br /> news & events</li>
                        </Link>
                        <Link to="/resource-vault" className={`pt-12 pb-9 hover:cursor-pointer lg:px-[0.64rem] xl:px-[1.12rem] 2xl:px-[1.48rem] hover:bg-[#6C6D70] hover:!text-white duration-500 ${active === 'resource-vault' ? 'theme-red' : ''}`}>
                            <li> مركز المصادر<br />resource vault</li>
                        </Link>
                        <Link to="/get-involved" className={`pt-12 pb-9 hover:cursor-pointer lg:px-[0.64rem] xl:px-[1.12rem] 2xl:px-[1.48rem] hover:bg-[#6C6D70] hover:!text-white duration-500 ${active === 'get-involved' ? 'theme-red' : ''}`}>
                            <li> شاركنا<br />get involved</li>
                        </Link>
                        <Link className='pt-12 pb-9 hover:cursor-pointer lg:px-[0.64rem] xl:px-[1.12rem] 2xl:px-[1.48rem] duration-500' onMouseEnter={handleopen} onMouseLeave={handleopen}>
                            <div className={`flex justify-center items-center relative lg:p-1 xl:p-1 rounded-full ${!userData?.image_url ? 'border border-black' : ''}`}>
                                <img src={userData?.image_url ? userData?.image_url : person} alt="person" className='rounded-full lg:h-[20px] lg:w-[20px] xl:h-[30px] xl:w-[30px]' style={{ objectFit: 'cover', height: '50px', width: '50px' }} />
                                {nav &&
                                    <div className='absolute lg:top-14 xl:top-[72px] xl:right-[12px] lg:right-[8px] bg-white flex flex-col lg:before:right-[-1px] xl:before:right-[5px] lg:w-[100px] xl:w-[150px] border border-black navbefore'>
                                        <Link to="/bidding" className={`py-3 text-center hover:cursor-pointer hover:bg-[#6C6D70] hover:!text-white duration-500 border-b ${active === 'bidding' ? 'theme-red' : ''}`}>bidding</Link>
                                        <Link to="/profile" className={`py-3 text-center hover:cursor-pointer hover:bg-[#6C6D70] hover:!text-white duration-500 border-b ${active === 'profile' ? 'theme-red' : ''}`}>profile</Link>
                                        <span className="py-3 hover:cursor-pointer text-center hover:bg-[#6C6D70] hover:!text-white duration-500 border-b" onClick={logOut}>log out</span>
                                    </div>}
                            </div>
                        </Link>
                    </ul>
                </div>
                <div className="xs:flex-1 text-right lg:hidden hover:cursor-pointer xs:px-4 sm:px-4">
                    <i className="fa fa-bars text-2xl" onClick={() => setMobile(!mobile)} aria-hidden="true"></i>
                </div>
                {mobile && (
                    <div id="mobile" className="fixed top-0 right-0 w-[70%] h-screen bg-black z-[9999999999] text-white border-l-[12px] border-[#D42234] uppercase duration-1000">
                        <ul className="relative flex flex-col gap-[30px] pt-[100px] xs:pl-14 sm:pl-20 md:pl-24 header_active">
                            <li className={active === 'leadership' ? 'theme-red' : ''}><Link to="/leadership">القيادة <br /> leadership</Link></li>
                            <li className={active === 'celebrating-people' ? 'theme-red' : ''}><Link to="/celebrating-people">نحتفل معكم <br /> celebrating people </Link></li>
                            <li className={active === 'hr-brief' ? 'theme-red' : ''}><Link to="/hr-brief">نشرات الموارد البشرية <br />hr briefs</Link></li>
                            <li className={active === 'news-events' ? 'theme-red' : ''}><Link to="/news-events"> الاحداث و الاخبار<br /> news & events</Link></li>
                            <li className={active === 'resource-vault' ? 'theme-red' : ''}><Link to="/resource-vault">مركز المصادر<br /> resource vault</Link></li>
                            <li className={active === 'get-involved' ? 'theme-red' : ''}><Link to="/get-involved">شاركنا<br /> get involved</Link></li>
                            <li className={active === 'bidding' ? 'theme-red' : ''}><Link to="/bidding">bidding</Link></li>
                            <li className={active === 'profile' ? 'theme-red' : ''}><Link to="/profile">صفحتي<br /> my profile</Link></li>
                        </ul>
                        <div className="absolute top-10 right-10 text-xl hover:text-[#D42234] cursor-pointer" onClick={() => setMobile(false)}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
