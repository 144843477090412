import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';

export const ItHelpDesk = (props) => {
    const [errorPage, setErrorPage] = useState(true);
    const [data, setData] = useState([]);
    const requestParams = [
        { name: 'it_help_desk', value: 'it_help_desk' }
    ];

    useEffect(() => {
        fetchArticleByType(requestParams, false, 2).then(({ data }) => {
            setData(data);
            setErrorPage(false);
        }).catch(() => setErrorPage(true));
    }, []);

    if (errorPage) {
        <div></div>;
    }
    const onClick = (url) => {
        window.location.href = `/articles/${url}`;
    };

    return (
        <>
            {data?.[0]?.id &&
                <div className="w-full bg-[#FDF4F5] pb-10" id="it-help-desk">
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">IT Help Desk</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}>See All <span className="uppercase">IT Help Desk</span></p></div>
                        </div>
                    </div>
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto flex flex-row flex-wrap gap-5 py-10">
                        {data?.map((value, i) => {
                            return <div key={i} className="xs:flex-[0_0_100%] md:flex-[0_0_100%] lg:flex-[0_0_49%] flex xs:flex-col md:flex-col lg:flex-row rounded-md">
                                {/* <div className="flex-1 flex flex-col justify-center items-center gap-5 bg-[#D42234] px-[30px] text-white">
                                    <i className="fa fa-headset text-[5rem]"></i>
                                    <h4 className="uppercase font-bold text-3xl">
                                        tawasul
                                    </h4>
                                </div> */}
                                <div className="px-8 flex flex-col gap-3 py-8 bg-white w-full">
                                    <div><h4 className="text-xl font-bold">{value?.title}</h4></div>
                                    <div className="h-2 w-14 bg-[#D42234] rounded-lg"></div>
                                    <div><p className='text-justify'>{value?.short_description}</p></div>
                                    <div>
                                        <p onClick={() => onClick(value?.alias_name)} className="hover:underline hover:decoration-[#D42234] text-[#D42234] text-right pr-4 hover:cursor-pointer">Read On</p>
                                    </div>
                                </div>
                            </div>;
                        })
                        }
                    </div>
                </div>
            }
        </>
    );
};
