import React, { useEffect, useState } from 'react';
import wifi from 'assets/media/wifi.jpg';
import { fetchArticleByType } from 'api/article';
import { ButtonStyle } from 'components/button';

export const CooFeedback = () => {
    const [errorPage, setErrorPage] = useState(true);
    const [data, setData] = useState([]);

    const requestParams = [
        { name: 'coo_feedback', value: 'coo_feedback' }
    ];

    useEffect(() => {
        fetchArticleByType(requestParams, false, 4).then(({ data }) => {
            setData(data);
            setErrorPage(false);
        }).catch(() => setErrorPage(true));
    }, []);

    if (errorPage) {
        return <div></div>;
    }

    return (
        <>
            {data?.[0]?.id && data?.map((value, i) => {
                return (<div className="w-full bg-white py-10" id="coo" key={i}>
                    <div className="sm:container xs:px-4 sm:px-4 lg:px-4 xl:px-4 mx-auto">
                        <div className="flex xs:flex-col sm:flex-col md:flex-row xs:gap-5 sm:gap-5 md:gap-5 lg:gap-18 xl:gap-20 items-center">
                            <div className="flex-1 flex flex-col xs:gap-4 sm:gap-4 md:gap-8">
                                <div>
                                    <h1 className="font-semibold text-2xl xs:w-full sm:w-[80%]"><span>اذا لديك فكره رائعة او مقترح يمكنك مشاركته مع الرئيس التنفيذي للعمليات</span> <br/ >{value.title}</h1></div>
                                <div>
                                    <p className="xs:w-full sm:w-[80%] text-justify">{value.short_description}</p></div>
                                <ButtonStyle title={'Go to site'} style={'w-2/4'} url={'http://www.feedback.zag/ceo-feedbacks'} />
                            </div>
                            <div className="xs:hidden flex-1 sm:flex justify-center items-center">
                                <img className="w-[90%]" src={wifi} alt="wifi" />
                            </div>
                        </div>
                    </div>
                </div>);
            })}
        </>
    );
};
