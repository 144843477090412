import Footer from 'components/Footer';
import { Loader } from 'components/loader';
import Header from 'components/navBar';
import React, { Component } from 'react';
import { Banner } from './banner';
import Blogs from './blog';

class GetInvolved extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 1,
            eventData: [],
            loader: true
        };
    }

    componentDidMount() {
        document.title = 'Tawasul - Get Involved';
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setTimeout(this.handleTimer, 3000);
    }

    handleTimer = () => {
        this.setState({ loader: false });
    }
    render() {
        const { loader } = this.state;
        return (
            <React.Fragment>
                {loader && <Loader />}
                <Header loader={loader} />
                <Banner />
                <Blogs />
                <Footer />
            </React.Fragment>
        );
    }
}
export default GetInvolved;
