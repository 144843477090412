import React, { useEffect, useState } from 'react';
import { getArticleData } from 'api/article';
import { path } from 'helpers';
import { Loader } from 'components/loader';
import Header from 'components/navBar';
import ArticleDetail from './article';
import { RelatedArticle } from './related_article';
import { TempThree } from './temp_three';
import { Comments } from './comments';
import Footer from 'components/Footer';
import { PdfViewer } from './temp_pdf';

export const Templates = () => {
    const [data, setData] = useState([]);
    const [errorPage, setErrorPage] = useState(true);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            getArticleData(path(window.location.pathname), false).then(({ data }) => {
                document.title = data.title;
                setData(data);
                setErrorPage(false);
                handleTimer();
            }).catch(() => setErrorPage(true));
        }, 2000);
    }, []);
    if (errorPage) {
        <div></div>;
    }
    const handleTimer = () => {
        setLoader(false);
    };
    return (
        <div>
            {loader && <Loader />}
            <Header loader={loader} />
            {data?.article_type?.alias_name === 'promotions' || data.template === 3 || data?.article_type?.alias_name === 'awards_recognition'
                ? <TempThree data={data} />
                : data.template === 4
                ? <PdfViewer data={data} multi={true}/>
                : <>
                    {data.template === 0 && <ArticleDetail data={data} />}
                    {data.template === null && <ArticleDetail data={data} />}
                    {data.template === 1 && <ArticleDetail data={data} />}
                    {data.template === 2 && <RelatedArticle data={data} />}
                </>
            }
            {data.comment &&
                <Comments />
            }
            <Footer />
        </div>
    );
};
