import React, { useEffect, useState } from 'react';
import { DummyImage } from 'config';
import { fetchArticleByType } from 'api/article';

export const Office365Training = (props) => {
    const [errorPage, setErrorPage] = useState(true);
    const [data, setData] = useState([]);
    const requestParams = [
        { name: 'office_training', value: 'office_training' }
    ];

    useEffect(() => {
        fetchArticleByType(requestParams, false, 3).then(({ data }) => {
            setData(data);
            setErrorPage(false);
        }).catch(() => setErrorPage(true));
    }, []);

    if (errorPage) {
        <div></div>;
    }

    return (
        <>
            {data?.[0]?.id &&
                <div className="w-full bg-[#f5f5f5] text-black" id="office365-training">
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">office365 Training</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}>See All <span className="uppercase">office365 Training</span></p></div>
                        </div>
                        <div className="flex flex-row flex-wrap gap-16 justify-between pb-16">
                            {data?.map((value, i) => {
                                return <div key={i} className="flex flex-col gap-10 hover:cursor-pointer sm:w-[25%] text-black xs:flex-[0_0_100%] sm:flex-[0_0_25%] hover:underline hover:decoration-2">
                                    <div className="flex-1">
                                        <img className="w-full h-full" src={value?.banner_image?.url || DummyImage} alt="... " />
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <div className="flex-1">
                                            <a href={value.external_link} target='_blank' className="text-2xl font-bold" rel="noreferrer">{value?.title}</a>
                                        </div>
                                    </div>
                                </div>;
                            })}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
