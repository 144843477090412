import React, { Component } from 'react';
import { deleteAllCookie, DateFormat } from 'helpers';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';

class Blogs extends Component {
    constructor(props) {
        super(props);
        this.requestParams = [
            { name: 'get_involved_blog', value: 'get_involved_blog' },
        ];
        this.state = {
            errorPage: false,
            data: []
        };
    }

    componentDidMount = () => {
        this.fetchData();
    }

    fetchData = () => {
        fetchArticleByType(this.requestParams, false).then(({ data }) => {
            this.setState({ data, errorPage: false });
        }).catch((error) => {
            if (error[0] === 401) {
                deleteAllCookie();
            }
            this.setState({ errorPage: true });
        });
    }

    showArticle = (alias_name) => {
        window.location.href = `/articles/${alias_name}`;
    }

    addPostPage = () => {
        window.location.href = '/get-involved/add-blog';
    }

    render() {
        const { data } = this.state;
        return (
            <div className="xs:px-4 sm:container mx-auto py-10 flex flex-col gap-5">
                <div className="sm:w-[80%] mx-auto">
                    <div className='flex justify-between pb-5'>
                        <div><h2 className='capitalize xs:text-xl sm:text-3xl font-bold'>{'ZAG Community Blog'}</h2></div>
                        <div>
                            <div className="relative rounded-sm border-t-2 text-center text-[#fff] bg-[#D42234] duration-150 comment-btn hover:cursor-pointer" onClick={this.addPostPage}>
                                <button className="uppercase py-2 px-5  duration-150">Add Post</button>
                            </div>
                        </div>
                    </div>
                    {data.map((value, i) => (
                        <div key={i} className="flex flex-col pt-6">
                            <div className="flex xs:flex-col sm:flex-row gap-5 pb-8">
                                <div className="xs:h-[300px] sm:h-[300px] md:h-[300px] lg:h-[400px] md:flex-[0.6_1_0%] xl:h-[300px] bg-no-repeat bg-center bg-cover xl:flex-[0.6_1_0%]"
                                    style={{ backgroundImage: `url(${value?.banner_image?.url || value?.title_image?.url || DummyImage})` }}>
                                </div>
                                <div className="flex-1 flex flex-col gap-4">
                                    <div className='hover:text-[#D42234]'>
                                        <h2
                                            onClick={() => {
                                                this.showArticle(value.alias_name);
                                            }
                                            }
                                            className="font-bold hover:cursor-pointer"
                                        >
                                            {value?.title}
                                        </h2>
                                    </div>
                                    <div>
                                        <p className="text-[#0009] text-justify" dangerouslySetInnerHTML={{ __html: value?.short_description }} />
                                    </div>
                                    <div>
                                        <p className="text-[#0009]">{`Posted ${value.employee_name ? ` by ${value.employee_name}` : ''}  on ${DateFormat(value?.created_at)}`}</p>
                                    </div>
                                </div>
                            </div>
                            <hr className="border-1 border-[#D42234]" />
                        </div>
                    ))}

                </div>
            </div>
        );
    }
} export default Blogs;
