import React, { Component } from 'react';
import Footer from 'components/Footer';
import Header from 'components/navBar';
import { Banner } from './banner';
import { Cars } from './car';
// import { MainBlock } from './bidding_mainblock';
import { Loader } from 'components/loader';

class Bidding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
        };
    }
    componentDidMount() {
        document.title = 'Tawasul - Bidding';
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setTimeout(this.handleTimer, 3000);
    }
    handleTimer = () => {
        this.setState({ loader: false });
    }
    render() {
        const { loader } = this.state;
        return (
            <>
            {loader && <Loader />}
            <Header loader={loader} />
            <Banner />
            <Cars />
            {/* <MainBlock /> */}
            <Footer />
            </>
        );
    }
}

export default Bidding;
