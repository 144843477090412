import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const Gathering = (props) => {
    const [data, setData] = useState([]);
    const [errorPage, setErrorPage] = useState(true);
    const requestParams = [
        { name: 'gathering', value: 'gathering' },
    ];
    const [showDots, setShowDots] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            fetchArticleByType(requestParams, false).then(({ data }) => {
                setData(data);
                setErrorPage(false);
            }).catch(() => setErrorPage(true));
        }, 2000);
        const screen_width = window.screen.width;
        screen_width < 640 ? setShowDots(false) : setShowDots(true);
    }, []);
    if (errorPage) {
        <div></div>;
    }
    const breakpoint = {
        desktop: {
            breakpoint: { max: 4000, min: 0 },
            items: 1
        }
    };

    return (
        <>
            {data?.[0]?.id &&
                <div className="bg-[#E6E6E6] pb-10" id="gathering">
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">Gathering</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}
                                >
                                    See All <span className="uppercase">gathering</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <Carousel
                        responsive={breakpoint}
                        arrows={false}
                        showDots={showDots}
                        autoPlay={true}
                        infinite={true}
                        className="sm:pb-20"
                        dotListClass="hr_dot_list"
                        autoPlaySpeed={5000}
                    >
                        {data.map((value, i) => (
                            <div key={i} className="xs:min-h-[500px] sm:min-h-[400px] flex xs:flex-col-reverse xs:px-4 sm:px-4 md:px-4 lg:px-4 sm:container sm:mx-auto sm:flex-col-reverse md:flex-row-reverse text-white xs:gap-[2rem] sm:gap-[2rem] md:gap-[2rem] lg:gap-[3rem] xl:gap-[4rem]">
                                <div className="sm:h-auto sm:flex-1 lg:flex-[0.9_1_0%] text-black flex flex-col xs:gap-10 sm:gap-10 sm:px-10 md:px-0 md:gap-10 lg:gap-16">
                                    <div className='xs:h-[600px] sm:min-h-[400px] overflow-hidden'>
                                        <div className="font-medium">
                                            <h1 className="">{value?.title}</h1>
                                        </div>
                                        <div>
                                            <p className='text-justify pt-8'>{value?.short_description}</p>
                                        </div>
                                    </div>
                                    <ButtonStyle title={'learn more'} style={''} url={`/articles/${value?.alias_name}`} />
                                </div>
                                <div className="xs:h-[300px] sm:h-[300px] md:h-[300px] lg:h-auto md:flex-[0.6_1_0%] lg:flex-1 w-full bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${value?.banner_image?.url || DummyImage})` }}></div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            }
        </>
    );
};
