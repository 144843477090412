import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';

export const Events = (props) => {
    const [data, setData] = useState([]);
    const [errorPage, setErrorPage] = useState(true);
    const requestParams = [
        { name: 'events', value: 'events' },
    ];

    useEffect(() => {
        setTimeout(() => {
            fetchArticleByType(requestParams, false).then(({ data }) => {
                setData(data);
                setErrorPage(false);
            }).catch(() => setErrorPage(true));
        }, 2000);
    }, []);

    const openArticle = (alias_name) => {
        window.location.href = `/articles/${alias_name}`;
    };

    if (errorPage) {
        <div></div>;
    }

    return (
        <>
            {data?.[0]?.id &&
                <div className="w-full bg-white pb-10" id="events">
                    <div className="sm:container mx-auto px-4">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">Events</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}` })}
                                >
                                    See All <span className="uppercase">events</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:container xs:px-4 xs:gap-3 sm:px-4 md:px-4 lg:px-4 mx-auto flex flex-row flex-wrap sm:gap-2 lg:gap-3 xl:gap-4 2xl:gap-5 pb-10">
                        {data.map((value, i) => {
                            return (
                                <div key={i} className="xs:flex-[0_0_100%] md:flex-[0_0_49%] lg:flex-[0_0_32.33333%] xl:flex-[0_0_32.33333%] 2xl:lg:flex-[0_0_32.33333%] flex flex-col rounded-lg border-2">
                                    <div className="xs:h-[400px] sm:h-[500px] md:h-[300px] lg:h-[300px] xl:h-[400px] 2xl:h-[500px] bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${value?.banner_image?.url || DummyImage})` }}></div>
                                    <div className="min-h-[200px] bg-white text-black flex-1 text-white flex flex-col p-8 gap-10">
                                        <div onClick={() => openArticle(value?.alias_name)} ><h2 className="hover:cursor-pointer font-semibold text-[#D42234]">{value?.title}</h2></div>
                                        <div><p className='text-justify text-left'>{value?.short_description}</p></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
        </>
    );
};
