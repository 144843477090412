import React, { Component } from 'react';
import Footer from './Footer';
import Header from './navBar';
import Carousel from 'react-multi-carousel';
import { biddingShowDetails, biddingSubmission } from 'api/bidding';
import { DummyImage } from 'config';
import { Loader } from './loader';
import Terms from 'data/terms_condition.json';

class BiddingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bidId: this.props.match.params.id,
            data: {},
            amount: '',
            successMgs: false,
            message: '',
            loading: true,
            bid_accept: false,
            terms: false,
        };
    }

    componentDidMount() {
        this.fetchbiddingData();
        document.title = 'Tawasul - Bidding';
        this.handleWindowScroll(0);
        setTimeout(this.handleTimer, 3000);
    }

    handleWindowScroll = (top) => {
        window.scrollTo({
            top: top,
            behavior: 'smooth',
        });
    }

    handleTimer = () => {
        this.setState({ loading: false });
    }

    fetchbiddingData = async () => {
        const id = this.state.bidId;
        await biddingShowDetails(id).then(({ data }) => {
            this.setState({ data });
        }).catch((error) => error[0] === 404 && this.props.history.push('/bidding/not-found'));
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = () => {
        const { data, amount, bid_accept } = this.state;
        console.log(amount);
        if (amount >= data?.start_amount && bid_accept) {
            biddingSubmission(data.id, { amount: amount }).then(({ message }) => {
                this.setState({ message: message === 'Saved Successfully...!' ? 'Your bid have been successfully submitted ' : 'Something went wrong', successMgs: true });
                this.setState({ amount: '', bid_accept: false });
                this.handleCheckClear();
                setTimeout(() => {
                    this.setState({ successMgs: false });
                }, 5000);
            }).catch((error) => {
                this.setState({ message: error[1].data[0], successMgs: true });
                setTimeout(() => {
                    this.setState({ successMgs: false });
                }, 5000);
            });
        } else if (amount !== '' && !bid_accept) {
                this.setState({ message: 'Please Accept Terms & Condition', successMgs: true });
                setTimeout(() => {
                    this.setState({ successMgs: false });
                }, 5000);
            } else if (amount === '' && bid_accept) {
                this.setState({ message: 'Bidding amount should not be blank', successMgs: true });
                setTimeout(() => {
                    this.setState({ successMgs: false });
                }, 5000);
            } else if (amount === '' && !bid_accept) {
                this.setState({ message: 'Bidding amount should not be blank And Please Accept Terms & Condition', successMgs: true });
                setTimeout(() => {
                    this.setState({ successMgs: false });
                }, 5000);
            } else if (amount < data.start_amount && bid_accept) {
                this.setState({ message: 'Bidding amount should be greater than starting amount', successMgs: true });
                setTimeout(() => {
                    this.setState({ successMgs: false });
                }, 5000);
            }
    }

    handleImage = (e) => {
        document.getElementById('main-image').src = e.target.src;
    }

    handleTerms = () => {
        this.handleScroll();
        this.setState({
            terms: !this.state.terms,
        });
    }

    handleScroll = () => {
        const doc = document.documentElement.style;
        doc.setProperty('--scroll', `${this.state.terms !== true ? 'hidden' : 'none'}`);
        doc.setProperty('--opacity', `${this.state.terms !== true ? 0.3 : 1}`);
        const top = window.screen.width <= 1024 ? 0 : 0;
        this.handleWindowScroll(top);
    }

    handleCheckClear = () => {
        document.getElementById('bid_accept').checked = false;
    }

    render() {
        const bidding_breakpoint = {
            bigDesktop: {
              breakpoint: { max: 4000, min: 1501 },
              items: 5
            },
            desktop: {
              breakpoint: { max: 1500, min: 1300 },
              items: 5
            },
            tab: {
              breakpoint: { max: 1300, min: 1000 },
              items: 4
            },
            tablet: {
              breakpoint: { max: 1000, min: 0 },
              items: 3
            },
            mobile: {
              breakpoint: { max: 768, min: 0 },
              items: 2
            }
          };
          const { data, amount, successMgs, message, loading, bid_accept, terms } = this.state;
          const { handleChange, handleSubmit, handleImage, handleTerms } = this;
          const images = data?.banner_image?.url || DummyImage;
        return (
            <>
            {loading && <Loader />}
            <Header loader={loading} />
            {terms && <div className='flex justify-center items-center '>
                <div className='absolute bg-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] xs:w-[350px] sm:w-[500px] md:w-[700px] lg:w-[800px] xs:h-[50vh] xs:min-h-[600px] sm:h-[92vh] 2xl:h-[80vh] border-2 z-[999999999999] terms'>
                    <div className='text-center border-b-2'>
                        <h2 className='p-3 text-[#D42234]'>Terms And Conditions</h2>
                    </div>
                    <div className='p-5 overflow-scroll xs:h-[75%] sm:h-[80%] bidding-scroll'>
                         <h1 className='text-xl text-center pb-5 underline'>Used cars bid’s T & Cs for ZAG staff</h1>
                        <p dangerouslySetInnerHTML={{ __html: Terms?.en }} className="pb-10"/>
                        <h1 className='text-xl text-center pb-5 underline'>شروط وأحكام عطاءات مزايدة السيارات المستعملة لموظفي مجموعة الزبير للسيارات <br /></h1>
                        <p dangerouslySetInnerHTML={{ __html: Terms?.ar }} style={{ direction: 'rtl' }}/>
                    </div>
                    <div className='p-5 border-t-2'>
                        <div className="relative rounded-sm border-t-2 text-center text-[#fff] bg-[#D42234] duration-150 comment-btn hover:cursor-pointer w-24 float-right" onClick={handleTerms}>
                            <button className="uppercase py-2 px-5  duration-150">Ok</button>
                        </div>
                    </div>
                </div>
            </div>}
            <div className='xs:p-4 sm:container lokesh md:container mx-auto'>
                <div className='flex items-center justify-center my-8 py-6' style={{ boxShadow: '4px 4px 25px rgba(0, 0, 0, 0.1)' }}>
                    <img src={images} alt="car" id='main-image' className='xs:h-[200px] sm:h-[500px]' style={{ objectFit: 'contain' }}/>
                </div>
                <Carousel responsive={bidding_breakpoint} arrows={true} autoPlay={false} className='biddind_container'>
                    {data?.images
                    ? data?.images?.map((value, i) => {
                        return (<div key={i} className='flex items-center flex-col justify-center xs:my-2 sm:my-8 mx-3 p-4 cursor-pointer' style={{ boxShadow: '4px 4px 25px rgba(0, 0, 0, 0.1)' }}>
                                    <img src={value?.url} alt="car" onClick={handleImage} className='xs:h-[80px] sm:h-[130px]' style={{ objectFit: 'contain' }} />
                                </div>);
                    })
                    : (<></>)}
                </Carousel>
                <div className='py-8'>
                    <div className='text-center'><h2>{data?.title}</h2></div>
                    <div className='text-justify' dangerouslySetInnerHTML={{ __html: data?.content }} />
                </div>
            </div>
            <div className='bg-[#FDF4F5]'>
                    <div className='xs:px-4 sm:container sm:mx-auto py-16 flex xs:flex-col xs:gap-5 sm:gap-5 sm:flex-col lg:flex-row'>
                        <div className='flex-1 xs:text-left sm:text-center lg:text-left'>
                        <p><span className='text-[#908E91] text-xl'>Starts from </span> RO {data?.start_amount}</p>
                        <p><span className='text-[#908E91] text-xl'>Bidding closes on {data?.close_date}</span></p>
                        </div>
                        <div className='flex-1 flex gap-4 flex-col sm:items-center'>
                            <div className='flex-1'>
                            <input className='xs:w-full sm:w-[600px] h-10 p-2' placeholder='Enter your amount' name='amount' value={amount} onChange={handleChange}/>
                            </div>
                            <div className='flex gap-2 items-center'>
                                <input type="checkbox" id='bid_accept' value={bid_accept} onChange={(e) => { handleChange({ target: { name: e?.target?.id, value: e?.target?.checked } }); }} />
                                <p className='underline cursor-pointer' onClick={handleTerms}>I accept the Terms and Conditions / أوافق على الشروط و الاحكام</p>
                            </div>
                            <div className='flex-1 text-center'>
                            <button className='bg-black text-white py-[10px] px-[40px] cursor-pointer' onClick={handleSubmit}>Bid</button>
                            </div>
                            <div className='relative w-full'>{successMgs && <div className='absolute top-[10px] w-full text-center text-[#d42234]'><p>{message}</p></div>}</div>
                        </div>
                    </div>
            </div>
            <Footer />
            </>
        );
    }
}

export default BiddingDetails;
