import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';

const Vision = () => {
  const [errorPage, setErrorPage] = useState(true);
  const [values, setValues] = useState({});
  const [mission, setMission] = useState({});
  const [vision, setVision] = useState({});
  const [data, setData] = useState({});

  const requestParams = [
    { name: 'vision_mission_values', value: 'vision_mission_values' }
  ];

  useEffect(() => {
    fetchArticleByType(requestParams, false).then(({ data }) => {
      setErrorPage(false);
      handleData(data);
      setData(data);
    }).catch(() => setErrorPage(true));
  }, []);

  const handleData = (data) => {
    data.forEach((value) => {
      if (value.title === 'Values') {
        setValues(value);
      }
      if (value.title === 'Mission') {
        setMission(value);
      }
      if (value.title === 'Vision') {
        setVision(value);
      }
    }
    );
  };

  if (errorPage) {
    return <div></div>;
  }
  return (
    <>
      {data?.[0]?.id &&
        <div className="w-full bg-[#DB3B44] text-white" id="vision">
          <div className="sm:container xs:px-4 sm:px-4 lg:px-4 xl:p-4 mx-auto">
            <div className='sm:container mx-auto flex xs:flex-col xs:gap-10 sm:flex-col sm:gap-10 md:flex-row md:gap-5 md:items-center lg:items-start lg:gap-8 xl:gap-10 2xl:gap-32 py-10'>
              <div className='flex-[2_1_0%] flex flex-col text-[#f8f9fa]'>
                <div><h2 className='uppercase font-bold'>{values?.title}</h2></div>
                <div>
                  <p className='text-justify pt-4 leading-normal lg:text[1.1rem] xl:text-[1.4rem] text-justify md:pr-10 lg:pr-10 xl:pr-16 2xl:pr-0'>
                    {values?.short_description}
                  </p>
                </div>
              </div>
              <div className='flex-1 text-black'>
                <div className='bg-white xs:p-12 sm:p-10 md:p-5 lg:p-8 xl:p-10 2xl:p-14 rounded-md flex flex-col sm:gap-8 md:gap-3 lg:gap-8 xl:gap-14' style={{ boxShadow: '0 0 20px 5px #0000004d' }}>
                  <div className='flex-1'>
                    <div><h2 className='font-medium'>{vision?.title}</h2></div>
                    <div>
                      <p className='pt-4 xs:text-[20px] md:text-[18px] lg:text-[20px] xl:text-[22px] text-[#0009]'>
                        {vision?.short_description}
                      </p>
                    </div>
                  </div>
                  <div className='flex-1'>
                    <div><h2>{mission?.title}</h2></div>
                    <div>
                      <p className='pt-4 xs:text-[20px] md:text-[18px] lg:text-[20px] xl:text-[22px] text-[#0009]'>
                        {mission?.short_description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default Vision;
