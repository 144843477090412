import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';
// import { DummyImage } from 'config';
import moment from 'moment';
import Calendar from 'components/NiceDate';

export const HolidaysAndTraining = () => {
    const [errorPage, setErrorPage] = useState(true);
    const [data, setData] = useState([]);
    const [dateList, setDateList] = useState([]);
    const [aliasName, setAliasName] = useState('');
    const [filterdData, setFilterData] = useState('');
    const [content, setContent] = useState({});
    const requestParams = [
        { name: 'holidays_training', value: 'holidays_training' }
    ];
    useEffect(() => {
        fetchArticleByType(requestParams, false, 6, moment().format('DD-MM-YYYY')).then(({ data }) => {
            const temp = [];
            data.forEach((days) =>
            (
                temp.push(moment(days.date).format('MM/DD/YYYY')))
            );
            setData(data.sort((a, b) => new Date(a.date) - new Date(b.date)));
            setFilterData(data.filter(a => new Date(a.date).getMonth() === new Date().getMonth()));
            setContent(filterdData[0]);
            setAliasName(filterdData[0]?.alias_name);
            setErrorPage(false);
            setDateList(temp);
        }).catch(() =>
            setErrorPage(true)
        );
    }, []);

    const selectedDate = (date) => {
        const obj = data.find(x => moment(x.date).format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY'));
        setAliasName(obj?.alias_name);
        setContent(obj || undefined);
        setFilterData(data.filter(a => new Date(a.date).getMonth() === new Date(date).getMonth()));
    };

    const openArticle = () => {
        window.location.href = `/articles/${aliasName}`;
    };

    if (errorPage) {
        return <div></div>;
    }
    return (
        <>
            <div className='w-full bg-black text-white' id='holidays'>
                <div className='sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 xl:px-4 mx-auto text-white'>
                    <div className='flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem]'>
                        <div className='xs:w-full sm:w-1/3'><p className='sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase'>{'Holidays & Training'}</p> </div>
                        {/* <div className='xs:w-full lg:w-[36%] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:cursor-pointer xs:text-left text-right xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px]'> <p>See All <span className='uppercase'>Holidays & training</span></p></div> */}
                    </div>
                </div>
                <div>
                    <div className='sm:container flex xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto xs:flex-col sm:flex-col md:flex-row gap-20 pb-10'>
                        <div className='flex flex-col flex-[0.4_1_0%] gap-10'>
                            <div className='flex-1 flex gap-1 flex-col'>
                                <Calendar temp={dateList} selectedDate={selectedDate} />
                            </div>
                            <div className='flex-1 flex flex-col gap-8' id='kk'>
                                {filterdData.map((value, i) => {
                                    return (
                                        <div key={i} className='flex-1 flex flex-col gap-2' style={{ cursor: 'pointer' }} onClick={() => { setContent(value); setAliasName(value?.alias_name); }} >
                                            <>
                                                <div >
                                                    <h3 className='text-[0.9rem] font-extralight underline'>{moment(value.date, 'YYYY/MM/DD').format('LL')}</h3>
                                                </div>
                                                <div>
                                                    <p className='text-justify text-[1.3rem] font-extralight' dangerouslySetInnerHTML={{ __html: `${value?.title}` }} />
                                                </div>
                                                {/* {value.address && <div>
                                                        <p className='text-[0.9rem] font-extralight'>Address Location a</p>
                                                    </div>} */}
                                            </>
                                            {/* } */}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='flex flex-[0.7_1_0%] flex-col gap-10'>
                            {content &&
                                <>
                                    <div className='flex gap-5'>
                                        <div className='flex-1 lg:text-[1.3rem]'><h2>{content?.title}</h2></div>
                                        {/* onClick={() => onClick(data.url)} */}
                                        <div>
                                            <div onClick={() => openArticle()}
                                                className='border-2 border-[#ffff] hover:bg-[#D42234] hover:border-[#D42234] hover:cursor-pointer text-center' style={{ width: '200px' }} >
                                                <button className='uppercase p-2 text-[#ffff]' >{'LEARN MORE'}</button>
                                            </div>
                                        </div>
                                        {/* <div className='flex-1 flex justify-end'><ButtonStyle title={'learn more'} style={'border-[#DF5E6B] w-[60%] bg-[#D42234] text-[#ffff]'} url={`/articles/${aliasName}`} />

                                        </div> */}
                                    </div>
                                    <div className='max-h-[500px]'>
                                        <p className='' dangerouslySetInnerHTML={{ __html: `${content?.short_description}` }} />
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
