import React, { Component } from 'react';
import footIcon from 'assets/media/footer_logo.png';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <div className="w-full bg-[#6C6D70]">
                <div className="sm:container xs:px-2 sm:px-4 md:px-4 lg:px-4 mx-auto flex flex-col">
                    <div className="flex xs:flex-col sm:flex-row flex-1">
                        <div className="flex xs:flex-col sm:flex-row flex-1">
                            <a href='http://hrmssrv/MenaITech/application/hrms/mename' target="_blank" rel="noreferrer" className='flex-1'><div className="flex-1 pl-4 xs:py-4 sm:py-4 md:pt-[1.8rem] md:pb-[1.7rem] lg:py-4 gap-4 flex bg-[#D42234] items-center">
                                <div className='w-[35px] '><img src={footIcon} className="object-fit" alt="..." /></div>
                                <div className="w-1/4"><h4 className="text-[#F9E0E2] uppercase">mename hrms</h4></div>
                            </div></a>
                            <a href='https://apps.powerapps.com/play/4c5f2c51-9353-4782-9ee9-6d884f605ad2?tenantId=cc58c16d-f206-49ff-b81b-be5ec0f83b11' target="_blank" rel="noreferrer" className='flex-1'><div className="flex-1 pl-4 gap-4 py-4 bg-[#DC4E5C] flex items-center">
                                <div className='w-[35px] '><img src="https://docs-assets.katomaran.tech/images/icons/2022/03/MicrosoftTeamsImage.png" className='object-fit' alt=".." /></div>
                                <div className='xs:w-[50%] sm:text-xs md:text-base md:w-[] lg:w-[100%] 2xl:w-[50%]'><h4 className="text-[#F9E0E2] uppercase">IT - Business Process Automation App</h4></div>
                            </div></a>
                        </div>
                        <div className="flex-1 xs:flex-col sm:flex-row flex ">
                            <a href='https://zubairautohelpdesk.freshservice.com/support/home' target="_blank" rel="noreferrer" className='flex-1'><div className="flex-1 pl-4 gap-4 xs:py-4 sm:py-4 md:pt-[1.8rem] md:pb-[1.7rem] lg:py-4 bg-[#E57A85] flex items-center">
                                <div className='w-[35px] '><img src="https://docs-assets.katomaran.tech/images/leaderboard/footer/2022/02/helpdesk.png" className='object-fit' alt=".." /></div>
                                <div className='w-[24%]'><h4 className="text-[#F9E0E2] uppercase">it helpdesk</h4></div>
                            </div></a>
                            <a href='https://zubairautogroup.sharepoint.com/sites/MyOffice365Training/SitePages/Get-started-with-Microsoft-365.aspx' target="_blank" rel="noreferrer" className='flex-1'><div className="flex-1 pl-4 xs:py-4 sm:py-4 md:pt-[1.8rem] md:pb-[1.7rem] lg:py-4 gap-4 bg-[#EEA6AD] flex items-center">
                                <div className='w-[35px] '><img src="https://docs-assets.katomaran.tech/images/leaderboard/footer/2022/02/office.png" alt="..." className='object-fit' /></div>
                                <div className='w-1/4'><h4 className="text-[#F9E0E2] uppercase">office365 training</h4></div>
                            </div></a>

                        </div>
                    </div>
                    <div className="flex flex-1 text-[#F9E0E2] xs:flex-col sm:flex-row">
                        <div className="sm:w-[50%] flex xs:flex-col sm:flex-col md:flex-row">
                            <a href='https://app.powerbi.com' target="_blank" rel="noreferrer" className='flex-1'><div className="flex-1 pl-4 py-6 gap-4 bg-[#484848] flex items-center">
                                <div className='w-[35px] '><img src="https://docs-assets.katomaran.tech/images/zubair_footer/2022/03/bi-dashboard.png" className='object-fit' alt="...." /></div>
                                <div className='w-1/4'><h4 className="text-[#ffff] uppercase">BI Dashboards
                                </h4></div>
                            </div></a>
                            <a href='https://apps.powerapps.com/play/3132787a-b074-49a7-94fb-0e077988509f?tenantId=cc58c16d-f206-49ff-b81b-be5ec0f83b11' target="_blank" rel="noreferrer" className='flex-1'>
                                <div className='flex-1 flex items-center pl-4 py-6 bg-[#666464] gap-5'>
                                    <div className="text-[25px]"><i className="fas fa-wrench"></i></div>
                                    <div><h4 className="xs:w-[50%] lg:w-[50%] uppercase">Maintenance Ticket App</h4></div>
                                </div>
                            </a>
                        </div>
                        <div className="sm:w-[50%] xs:items-center bg-[#908E8E] flex xs:flex-col sm:flex-col md:flex-row justify-between">
                            {/* <div className='flex-[0.5_1_0%]'><h4 className="w-[10%] uppercase">zubair app</h4></div> */}
                            <a href='https://apps.powerapps.com/play/ed9a1a4a-f0f7-49cc-9bb6-4c61b8ee8435?tenantId=cc58c16d-f206-49ff-b81b-be5ec0f83b11' target="_blank" rel="noreferrer" className='xs:w-full md:w-[50%]'>
                                <div className='flex items-center md:pl-2 pl-4 py-6  bg-[#787878] gap-5'>
                                    <div className="text-[25px]"><i className="fas fa-shopping-cart"></i></div>
                                    <div className="xs:!w-[50%] sm:!w-[50%] lg:!w-[50%] xl:!w-[42%] uppercase"><h4>e-Procurement App</h4></div>
                                </div>
                            </a>
                            <div className='xs:w-full md:w-[50%] flex xs:flex-row sm:flex-row md:flex-col md:gap-1 md:items-center lg:flex-row xs:gap-3 pl-4 xs:py-6 md:py-4 lg:py-6 justify-center'>
                                <a href='https://apps.apple.com/om/app/zubair-automotive-group/id1453015915' target="_blank" rel="noreferrer"><div className='xs:!w-[130px] sm:!w-[100px] md:!w-[100px] lg:!w-[111px] xl:!w-[135px] 2xl:!w-[150px] pr-3'><img src="https://docs-assets.katomaran.tech/images/leaderboard/footer/2022/02/app_store.png" className='object-fit' alt=".." /></div></a>
                                <a href="https://play.google.com/store/apps/details?id=com.zubair.android&hl=en" target="_blank" rel="noreferrer"><div className='xs:!w-[130px] sm:!w-[100px] md:!w-[100px] lg:!w-[111px] xl:!w-[135px] 2xl:!w-[150px] pr-3'><img src="https://docs-assets.katomaran.tech/images/leaderboard/footer/2022/02/NicePng_google-play-png_263038.png" className='object-fit' alt="" /></div></a>
                            </div>
                        </div>
                    </div>
                    <div className="flex xs:flex-col sm:flex-row text-[#F9E0E2] xs:gap-10 sm:gap-[8rem] md:gap-10 md:justify-between sm:items-center xs:pt-10 sm:pt-20">
                        <div className="sm:flex-[0.6_1_0%] md:flex-[0.3_1_0%] lg:flex-[0.3_1_0%] xl:flex-[0.6_1_0%]">
                            <Link to="/home"><img src="https://docs-assets.katomaran.tech/images/leaderboard/footer/2022/02/Group_2.png" alt="logo" className='object-cover xs:w-[50%] sm:w-[80%] md:w-[90%] lg:w-[70%] xl:w-[60%] 2xl:w-[50%]' /></Link>
                        </div>
                        <div className="sm:flex-[1_1_0%] md:flex-[1.5_1_0%] lg:flex-[1.5_1_0%] xl:flex-[2_1_0%]">
                            <ul className="md:text-[0.7rem] lg:text-[0.7rem] xl:gap-0 xl:text-[0.9rem] uppercase flex xs:pl-[15%] sm:pl-[0%] xs:flex-col xs:gap-4 sm:flex-col md:flex-row lg:flex-row md:gap-10 lg:gap-10 justify-between">
                                <li className=""><Link to="/leadership">القيادة <br/>leadership</Link></li>
                                <li className=""><Link to="/celebrating-people">نحتفل معكم <br />celebrating people</Link></li>
                                <li className=""><Link to="/hr-brief">نشرات الموارد البشرية <br /> hr briefs</Link></li>
                                <li className=""><Link to="/news-events">الاحداث و الاخبار<br /> news & events</Link></li>
                                <li className=""><Link to="/resource-vault">مركز المصادر<br /> resource vault</Link></li>
                                <li className=""><Link to="/get-involved">شاركنا <br />get involved</Link></li>
                                <li className=""><Link to="/profile">صفحتي<br /> my profile</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex xs:flex-col xs:gap-4 sm:gap-24 md:gap-3 lg:gap-[1rem] xl:gap-[8rem] sm:flex-row text-[#F9E0E2] text-[0.9rem] md:justify-between lg:justify-between xl:justify-between 2xl:justify-between xs:pl-[15%] sm:pl-[0%] sm:items-center py-20 text-[#949799]">
                        <div className="lg:flex-[0.2_1_0%] xl:flex-[0.4_1_0%] 2xl:flex-[0.4_1_0%]"><p className='md:text-[0.9rem]'><span className="uppercase">@2022 zag.</span> All rights reserved</p></div>
                        <div className="flex flex-row lg:flex-1 xs:flex-col xs:gap-4 sm:gap-2 sm:flex-col md:items-center md:flex-row lg:flex-row xl:flex-[2_1_0%]">
                            <div className="md:flex-[2_1_0%] xl:flex-[1.7_1_0%] flex-1 flex sm:flex-col xs:gap-4 sm:gap-2 md:flex-col lg:flex-row justify-around xs:flex-col xs:gap-1 md:gap-[5px] lg:gap-[9px] xl:gap-[20px] 2xl:gap-[33px]">
                                <div className="md:flex-1 lg:flex-1">
                                    <a href='tel:24500000'><p className='md:!text-[0.8rem] lg:!text-[0.9rem] xl:!text-[0.9rem] 2xl:!text-[1rem]'>24500000</p></a>
                                </div>
                                <div className="xs:hidden sm:hidden md:hidden lg:block border-2 border-[#949799]"></div>
                                <div className="md:flex-1 lg:flex-[1_1_0%]"><a href="mailto:tawasul@za-oman.com"><p className='lg:!text-[0.9rem] xl:!text-[0.9rem] 2xl:!text-[1rem]'>tawasul@za-oman.com</p></a></div>
                                <div className="xs:hidden sm:hidden md:hidden lg:block border-2 border-[#949799]"></div>
                                <div className="md:flex-1 lg:flex-[2_1_0%]"><p className='lg:!text-[0.9rem] xl:!text-[0.9rem] 2xl:!text-[1rem]'>P.O. Box 800, P.C. 111, Muscat, Sultanate of Oman</p></div>
                                <div className="xs:hidden sm:hidden md:hidden lg:block border-2 border-[#949799]"></div>
                            </div>
                            <div className="xs:text-left sm:text-left md:text-right uppercase md:text-[0.8rem] lg:text-[0.8rem] xl:text-[0.9rem] md:flex-[1_1_0%] lg:flex-[0.22_1_0%] xl:flex-[0.3_1_0%] 2xl:flex-[0.3_1_0%]">
                                <div>
                                    <a href={'https://docs-assets.katomaran.tech/files/leaderboard/footer/2022/02/zubair-auto-ethical-code-of-conduct-en.pdf'} target='_blank' rel="noreferrer">
                                        <h4>Ethical Conduct</h4>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
