import React, { Component } from 'react';
import { fetchArticleByType } from 'api/article';
import { ButtonStyle } from 'components/button';
import { DummyImage } from 'config';
import { articleNew, deleteAllCookie } from 'helpers';

class ArticleOfMonth extends Component {
  constructor(props) {
    super(props);
    this.requestParams = [
      { name: 'article_of_the_month', value: 'article_of_the_month' },
    ];
    this.state = {
      data: [],
      errorPage: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    fetchArticleByType(this.requestParams, true, 1)
      .then(({ data }) => {
        this.setState({ data, errorPage: false });
      })
      .catch((error) => {
        if (error[0] === 401) {
          deleteAllCookie();
        }
        this.setState({ errorPage: true });
      });
  };

  render() {
    const { loader } = this.props;
    const { data, errorPage } = this.state;

    if (loader) {
      return <></>;
    }

    if (errorPage) {
      return <div></div>;
    }

    return (
      <>
        {data[0] && (
          <div className="bg-[#231F1F] flex xs:gap-0 sm:gap-10 md:gap-10  lg:gap-18 xl:gap-20 flex-col pb-[2rem]">
            <div className="flex xs:flex-col xs:gap-[1rem] sm:gap[0rem] xs:px-4 sm:px-4 md:px-4 lg:px-4 sm:flex-row justify-between sm:container sm:mx-auto py-[3rem] z-[99999]">
              <div className="xs:w-full sm:w-1/3 text-[#8E9092] font-light items-center">
                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] uppercase font-medium">
                  article of the month
                </p>
              </div>
              <div className="lg:w-[36%] text-[#8E9092] xs:text-left text-right xl:w-1/3 sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline font-light hover:cursor-pointer">
                <p
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/category/${this.requestParams[0].name}`,
                      state: { fetchValues: this.requestParams },
                    })
                  }
                  to={`/category/${this.requestParams[0].name}`}
                  className="hover:underline font-light"
                >
                  See All{' '}
                  <span className="uppercase">article of the month</span>
                </p>
              </div>
            </div>
            <div className="min-h-[300px] flex xs:flex-col-reverse xs:px-4 sm:px-4 md:px-4 lg:px-4 sm:container sm:mx-auto sm:flex-col-reverse md:flex-row text-white xs:gap-[2rem] sm:gap-[2rem] md:gap-[2rem] lg:gap-[3rem] xl:gap-[4rem]">
              <div className="md:flex-1 lg:flex-[0.9_1_0%] flex flex-col xs:gap-10 sm:gap-10 sm:px-10 md:px-0 md:gap-10 lg:gap-16">
                <div className="font-medium">
                  <h1 className="">{data[0]?.title}</h1>
                </div>
                <div>
                  <p className="text-justify">{data[0]?.short_description}</p>
                </div>
                <ButtonStyle
                  title={'learn more'}
                  style={'border-[#813C43] text-[#813C43]'}
                  url={`/articles/${data[0]?.alias_name}`}
                />
              </div>
              <div
                className="xs:h-[300px] sm:h-[300px] md:h-[300px] lg:h-auto md:flex-[0.6_1_0%] lg:flex-1 w-full bg-no-repeat bg-center bg-cover relative"
                style={{
                  backgroundImage: `url(${data[0]?.banner_image?.url || DummyImage})`,
                }}
              >
                <div
                  className={`new ${articleNew(data[0].published_at) ? '' : 'hidden'}`}
                >
                  <div className="new-clip">
                    <p className="absolute top-[-30px] left-0 rotate-45 text-white font-bold">
                      NEW
                    </p>
                  </div>
                </div>
              </div>
            </div>
            )
          </div>
        )}
      </>
    );
  }
}
export default ArticleOfMonth;
