import React, { useEffect, useState } from 'react';
// import role from 'assets/media/resource.jpg';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';

export const SalesServiceProcess = (props) => {
    const [errorPage, setErrorPage] = useState(true);
    const [data, setData] = useState([]);
    const requestParams = [
        { name: 'sales', value: 'sales' },
        { name: 'service', value: 'services' }
    ];

    useEffect(() => {
        fetchArticleByType(requestParams, false, 3).then(({ data }) => {
            setData(data);
            setErrorPage(false);
        }).catch(() => setErrorPage(true));
    }, []);

    if (errorPage) {
        <div></div>;
    }
    return (
        <>
            {data?.[0]?.id &&
                <div className="w-full bg-white" id="sales-service">
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">Sales & Services Process</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right"> <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}>See All <span className="uppercase">Sales & Services Process</span></p></div>
                        </div>
                    </div>
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto xs:gap-3 flex flex-row flex-wrap sm:gap-2 lg:gap-3 xl:gap-4 2xl:gap-5 pb-10">
                        {data?.map((value, i) => {
                            return (<div key={i} className="xs:flex-[0_0_100%] md:flex-[0_0_49%] lg:flex-[0_0_32.33333%] xl:flex-[0_0_32.33333%] 2xl:lg:flex-[0_0_32.33333%] flex flex-col rounded-lg border-2">
                                <div className="xs:h-[400px] sm:h-[500px] md:h-[300px] lg:h-[300px] xl:h-[300px] 2xl:h-[400px] bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${value?.banner_image?.url || DummyImage})` }}>
                                </div>
                                <div className="bg-white text-black flex-1 text-white flex flex-col p-8 gap-10">
                                    <div className='md:min-h-[550px] lg:min-h-[600px] xl:min-h-[450px] 2xl:min-h-[350px]'>
                                        <div><h1 className="font-semibold text-xl text-[#D42234]">{value?.title}</h1></div>
                                        <div><p className='text-left text-justify pt-5'>{value?.short_description}</p></div>
                                    </div>
                                    <ButtonStyle title={'read on'} style={'border-[#DF5E6B] text-[#DF5E6B]'} url={`/articles/${value?.alias_name}`} />
                                </div>
                            </div>);
                        })}
                    </div>
                </div>
            }
        </>
    );
};
