import React from 'react';
import big from 'assets/media/bid.png';
import Header from './navBar';

export const BiddingErrorPage = () => {
  return (
    <>
      <Header />
      <div className="sm:container mx-auto py-10 xs:px-4">
        <div className="flex flex-col">
          <div
            className="xs:h-[400px] sm:h-[300px] md:h-[300px] lg:h-[500px] w-full bg-no-repeat bg-center xs:bg-contain relative"
            style={{ backgroundImage: `url(${big})` }}
          ></div>
          <div className="flex-1 flex flex-col justify-center items-center">
            <h1 className='xs:text-[29px]'>This bid is not active now!</h1>
            <p></p>
          </div>
        </div>
      </div>
    </>
  );
};
