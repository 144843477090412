import React, { Component } from 'react';
import { fetchArticleByType } from 'api/article';
import Carousel from 'react-multi-carousel';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';
import { deleteAllCookie } from 'helpers';

class Banner extends Component {
    constructor(props) {
        super(props);
        this.requestParams = [
            { name: 'Leadership Banner', value: 'leadership_banner' }
        ];
        this.state = {
            data: [],
            errorScript: true
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        fetchArticleByType(this.requestParams, false, 5, null, 'position').then(({ data }) => {
            this.setState({ data });
        }).catch((error) => {
            if (error[0] === 401) {
                deleteAllCookie();
            }
            this.setState({ errorScript: false });
        });
    }

    render() {
        const breakpoint = {
            desktop: {
                breakpoint: { max: 4000, min: 0 },
                items: 1
            }
        };

        if (this.state.errorScript) {
            <div></div>;
        }
        const { data } = this.state;

        return (
            <React.Fragment>
                {data?.[0]?.id &&
                    <Carousel
                        responsive={breakpoint}
                        showDots={true}
                        className="xs:bg-white"
                        dotListClass="banner_dot_list"
                        infinite={false}
                        autoPlay={false}
                        autoPlaySpeed={10000}
                        arrows={false}
                    >
                        {data.map((value, i) => {
                            const image = value?.banner_image?.url || DummyImage;
                            return (
                                <>
                                    <div key={i} className="sm:h-[650px] md:h-[700px] lg:h-[750px] xs:px-4 bg-white flex xs:flex-col sm:flex-row xs:gap-3 sm:gap-4 md:gap-8 lg:gap-12 2xl:gap-20">
                                        <div className="xs:h-[400px] sm:h-auto sm:flex-1 md:flex-[1.4_1_0%] lg:flex-[1.6_1_0%] bg-no-repeat xs:bg-cover sm:bg-cover bg-center" style={{ backgroundImage: `url(${image})` }}>
                                        </div>
                                        <div className="flex-1 xl:pr-6">
                                            <div className="relative xs:text-[1.4rem] sm:text-[1.8rem] md:text-[1.7rem] lg:top-0 lg:text-[2rem] xl:text-[2.3rem] font-medium xl:top-0 xs:right-[0%] sm:right-[36%] xs:pl-5 sm:py-2 sm:pl-5 md:py-10 md:pl-10 lg:pl-16 lg:py-[5rem] xl:px-20  xl:py-[7rem] bg-white text-right xl:w-[135%] 2xl:w-[120%]">
                                                <h1>{value.title}</h1>
                                            </div>
                                            <div className="xs:px-4 xs:pt-5 md:pr-0 lg:pr-0 xs:w-full sm:w-[80%]">
                                                <div>
                                                    <p className='text-justify leading-9'>{value.short_description}</p>
                                                </div>
                                                <ButtonStyle title={'read on'} style={'mt-14 mb-[50px]'} url={`/articles/${value?.alias_name}`} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </Carousel>
                }
            </React.Fragment>
        );
    }
}

export default Banner;
