import React from 'react';
import zubairLogo from 'assets/media/zag-logo-2.svg';

export const RightSide = () => {
    return (
        <>
            <div className="illustration-wrapper" id='rightSide'>
                <img src={zubairLogo} alt="Login" />
            </div>
            <div id='rightSide' style={{ border: '2px solid black', height: '542px', background: 'black' }} />
        </>
    );
};
