import { DummyImage } from 'config';
import React from 'react';

export const Leaders = ({ data }) => {
    return (
        <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto">
            <div className="flex justify-between xs:flex-row sm:flex-row text-white sm:container mx-auto z-[99999] py-[3rem]">
                <div className="xs:w-full sm:w-2/4 md:w-1/3 font-light">
                    <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] uppercase font-medium">LeaderShip</p>
                </div>
            </div>
            <div className="flex flex-row flex-wrap gap-16 justify-between pb-16">
                {data.map((ite, i) => {
                    return (
                        <div
                            key={i}
                            className="flex flex-col gap-10 hover:cursor-pointer sm:w-[25%] text-white xs:flex-[0_0_100%] sm:flex-[0_0_44%] lg:flex-[0_0_25%] xl:flex-[0_0_20%] birth_day"
                        >
                            <div className="h-[250px] overflow-hidden">
                                <img className="w-full h-full img_class object-cover" src={ite.image_url || DummyImage} alt="... " />
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className="flex-1">
                                    <h4 className="xs:text-2xl sm:text-[1.3rem] lg:text-[1.2rem] xl:text-2xl">
                                        {ite.first_name || 'First Name'} {ite.last_name || 'Last Name'}
                                    </h4>
                                </div>
                                <div className="flex-1 flex justify-between">
                                    <div>
                                        <p className="">
                                            {ite.designation || 'Position'}
                                        </p>
                                    </div>
                                    <div className="flex flex-row gap-2 text-white">
                                        {ite.linked_in_url && <a href={ite.linked_in_url} target="_blank" rel="noreferrer" className="flex-1"><div>
                                            <i className="fab fa-linkedin-in" aria-hidden="true" style={{ fontSize: '1.5rem' }} />
                                        </div></a>}
                                        {ite.twitter_url && <a href={ite.twitter_url} target="_blank" rel="noreferrer" className="flex-1"><div>
                                            <i className="fab fa-twitter" style={{ fontSize: '1.5rem' }} />
                                        </div></a>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>

    );
};
