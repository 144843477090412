/* eslint-disable dot-notation */
import { Subscription } from 'api/subscription';
import React, { Component } from 'react';

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                email: ''
            }
        };
    }
    getEmail = () => {
        const { data } = this.state;
        Subscription(data).then(({ data }) => {
            this.setState({ data: { email: '' } });
        }).catch(error => {
            console.log('error', error);
        });
    }
    onChange = (e) => {
        const { data } = this.state;
        data[e.target.id] = e.target.value;
        this.setState({ data });
    }
    render() {
        const { getEmail, onChange } = this;
        const btn = document.getElementById('btn');
        const inputField = document.getElementById('email');
        btn?.addEventListener('click', () => {
            inputField.value = '';
        });
        return (
            <div className="hidden w-full bg-[#D5D5D5]">
                <div className="sm:container xs:p-4 sm:px-4 md:px-4 lg:px-4 py-[4rem] mx-auto flex xs:flex-col sm:flex-col md:flex-row py-10 xs:gap-5 sm:gap-10 md:gap-10 lg:gap-20 xl:gap-24 md:items-center">
                    <div className="md:flex-1"><h4 className="xs:text-[1.3rem] sm:text-[1.4rem] md:text-[1.4rem] lg:text-[1.5rem] xl:text-[1.99rem] font-medium">Sign up for the Leaderboard alerts to get all ZAG news as they happen.</h4></div>
                    <div className="flex-1 flex flex-col xs:gap-4 lg:gap-10">
                        <div className="flex xs:flex-col sm:flex-row md:flex-col xs:gap-5 sm:gap-6">
                            <div className="flex-[1_1_0%]">
                                <input id='email' className="w-full py-2 px-3 focus:!outline-none" type="text" placeholder="Email Address*" required onChange={(e) => onChange(e)} />
                            </div>
                            <div className="flex-[0.5_1_0%] text-center">
                                <button id="btn" className="bg-black py-2 px-6 rounded-lg text-white" onClick={getEmail}>Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Feedback;
