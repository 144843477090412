import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';
import { articleNew } from 'helpers';

class HrAnnouncement extends Component {
  constructor(props) {
    super(props);
    this.requestParams = [
      { name: 'promotions', value: 'promotions' },
      { name: 'new_recruits', value: 'new_recruits' },
      { name: 'open_position', value: 'open_position' },
    ];
    this.state = {
      data: [],
      errorPage: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    fetchArticleByType(this.requestParams, true)
      .then(({ data }) => {
        this.setState({ data, errorPage: false });
      })
      .catch(() => this.setState({ errorPage: true }));
  };

  render() {
    const breakpoint = {
      bigDesktop: {
        breakpoint: { max: 4000, min: 1501 },
        items: 4,
      },
      desktop: {
        breakpoint: { max: 1500, min: 1300 },
        items: 3,
      },
      tab: {
        breakpoint: { max: 1300, min: 640 },
        items: 2,
      },
      tablet: {
        breakpoint: { max: 639, min: 0 },
        items: 1,
      },
    };
    const { data, errorPage } = this.state;

    if (errorPage) {
      return <div></div>;
    }

    return (
      <div>
        {data?.[0]?.id && (
          <>
            <div className="flex xs:flex-col xs:gap-[.1rem] sm:gap-[0rem] sm:flex-row justify-between sm:container sm:mx-auto px-4 z-[99999]  py-[3rem]">
              <div className="xs:w-full sm:w-1/3 flex justify-between text-[#8E9092] font-medium">
                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] uppercase">
                  hr announcements
                </p>
              </div>
              <div className="text-[#8E9092] lg:w-[36%] xs:text-left text-right xl:w-1/3 sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline font-light hover:cursor-pointer">
                <p
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/category/${this.requestParams[1].name}`,
                      state: { fetchValues: this.requestParams },
                    })
                  }
                >
                  See All{' '}
                  <span className="uppercase font-light">hr announcements</span>
                </p>
              </div>
            </div>
            <Carousel
              autoPlaySpeed={5000}
              responsive={breakpoint}
              arrows={false}
              showDots={false}
              autoPlay={true}
              infinite={true}
              className="flex"
            >
              {data.map((value, i) => {
                const image = value?.banner_image?.url;
                const new_article = articleNew(value?.published_at);
                return (
                  <div
                    key={i}
                    className="flex flex-col xs:w-full w-[96%] 2xl:min-h-[400px]"
                  >
                    <div
                      className="xs:h-[300px] sm:h-[300px] md:h-[400px] bg-center w-full bg-no-repeat bg-cover"
                      style={{ backgroundImage: `url(${image || DummyImage})` }}
                    >
                      <div
                        className={`hr-new ${new_article ? '' : 'hidden'}`}
                      >
                        <div className="hr-new-clip">
                          <p className="absolute top-[-30px] left-0 rotate-45 text-white font-bold">
                            NEW
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1= flex flex-col bg-[#FDF4F5] py-8 xs:px-5 sm:px-12 gap-10">
                      <div className="xs:h-[200px] sm:h-[300px] xl:h-[220px] overflow-hidden flex flex-col gap-8">
                        <div className="flex justify-between text-[#908E91] capitalize pr-10">
                          <p style={{ fontFamily: 'Ubuntu', fontSize: '1rem' }}>
                            {value.title}
                          </p>
                        </div>
                        <div className="w-full">
                          <p style={{ fontSize: '1.3rem' }}>
                            {value.short_description}
                          </p>
                        </div>
                      </div>
                      <ButtonStyle
                        title={
                          value.article_type.alias_name === 'open_position'
                            ? 'apply'
                            : 'congratulate'
                        }
                        sytle={''}
                        url={`/articles/${value?.alias_name}`}
                      />
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </>
        )}
      </div>
    );
  }
}
export default HrAnnouncement;
