import React, { useState, useEffect, useRef } from 'react';
import Header from 'components/navBar';
import '../assets/tailwind/output.css';
import { fetchUserData, updateProfile } from 'api/user';
import { logout } from 'api/authentication';
import { DateFormat, deleteAllCookie, setCookie } from 'helpers';
import { Loader } from 'components/loader';

const Profile = () => {
    const [data, setData] = useState([]);
    const fileInputRef = useRef(null);
    const [imgeUrl, setImgeUrl] = useState('')
    const [ImgObj, setImgObj] = useState('')
    const [error, setError] = useState({ errorSts: false, message: '' })
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        fetchUserData()
            .then(({ data }) => {
                setData(data);
                if (data?.image_url) {
                    const dummyURL = new URL(data?.image_url);
                    const url = dummyURL?.toString();
                    setImgeUrl(url);
                    setCookie('userProfile', url);
                } else {
                    setImgeUrl('');
                    setCookie('userProfile', '');
                }
                setLoader(false);
                setError({ errorSts: false, message: '' });
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const logOut = () => {
        logout()
            .then(response => {
                console.log(response.message);
                deleteAllCookie();
            })
            .catch(error => {
                console.log('error', error);
            });
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file);
            setImgeUrl(fileURL);
            setImgObj(file)
        }
    };

    const profileUpadte = () => {
        setLoader(true)
        const formData = new FormData()
        formData.append('image', ImgObj)
        updateProfile(formData)
            .then(({ data }) => {
                fetchData()
                setImgObj('')
                window.location.reload()
            })
            .catch((err) => {
                console.log(err, 'errrrrrrrrrrrrrrrr');
                setError({ errorSts: true, message: error?.[1]?.message || 'Somthing Went Wrong' })
                setLoader(false)
            })
    }

    return (
        <>
            {loader && <Loader />}
            <Header />
            <div className='w-full flex justify-center py-10 items-center'>
                <div className='sm:container sm:mx-auto flex p-8 bg-[#F1F4F5] rounded-md xs:flex-col xs:gap-10 sm:flex-col sm:gap-10 md:flex-row md:gap-5 lg:gap-10 xl:gap-20'>
                    <div className='flex flex-col rounded-lg xs:w-full sm:w-full md:w-[200px] md:h-[400px] lg:w-[300px] lg:h-[500px] xl:w-[400px] xl:h-[600px] bg-white p-8 items-center'>
                        <div
                            className={`relative flex justify-center items-center rounded-full overflow-hidden 
        xs:w-[150px] xs:h-[150px] sm:w-[150px] sm:h-[150px] md:w-[100px] md:h-[100px] 
        lg:w-[200px] lg:h-[200px] xl:w-[200px] xl:h-[200px] 2xl:w-[250px] 2xl:h-[250px] 
        ${imgeUrl ? '' : 'bg-black'}`}
                            style={{ cursor: 'pointer' }}
                            onClick={handleClick}
                        >
                            {imgeUrl && (
                                <img
                                    src={imgeUrl}
                                    alt="Profile"
                                    className='object-cover rounded-full xs:w-[150px] xs:h-[150px] sm:w-[150px] sm:h-[150px] md:w-[100px] md:h-[100px] lg:w-[200px] lg:h-[200px] xl:w-[200px] xl:h-[200px] 2xl:w-[250px] 2xl:h-[250px]'
                                />
                            )}
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                accept=".jpg,.jpeg,.png"
                            />
                            {!imgeUrl && (
                                <div className="absolute inset-0 flex justify-center items-center">
                                    <h1 className={`text-white xs:text-[7rem] sm:text-[7rem] md:text-[5rem] lg:text-[9rem] ${data?.img_url ? 'hidden' : ''}`}>
                                        {data?.name?.charAt(0)}
                                    </h1>
                                </div>
                            )}
                            <div
                                className="absolute inset-0 flex items-center justify-center transition-opacity duration-300 opacity-0 hover:opacity-100"
                                style={{ backgroundColor: 'rgba(4, 15, 16, 0.3)' }} // #040F10 with 30% opacity
                            >
                                <span className="text-white text-xl">CHANGE</span>
                            </div>
                        </div>

                        <div className='pt-5'><h2 className="capitalize text-center">{data?.name}</h2></div>
                        <div className='pt-2'><p>{data?.phone}</p></div>
                        <div className='pt-10 flex gap-16 justify-between'>
                            <div className='text-2xl text-[#AFB6B9]'>{data.linked_in_url && <a href={data.linked_in_url} target='_blank' rel='noreferrer' ><i className='fab fa-linkedin-in'></i></a>}</div>
                            <div className='text-2xl text-[#AFB6B9]'>{data.twitter_url && <a href={data.twitter_url} target='_blank' rel='noreferrer' ><i className='fab fa-twitter'></i></a>}</div>
                        </div>
                        <div className='pt-5 flex gap-5'>
                            <div className="relative rounded-sm border-t-2 text-center text-[#fff] bg-[#D42234] duration-150 comment-btn hover:cursor-pointer" onClick={logOut}>
                                <button className="uppercase py-2 px-5  duration-150">Log out</button>
                            </div>
                            {ImgObj && <div className="relative rounded-sm border-t-2 text-center text-[#fff] bg-[#D42234] duration-150 comment-btn hover:cursor-pointer" onClick={profileUpadte}>
                                <button className="uppercase py-2 px-5  duration-150">Update</button>
                            </div>}
                        </div>
                        {error?.errorSts && <div className='relative w-full'>{<div className='absolute top-[10px] w-full text-center text-[#d42234]'><p>{error?.message}</p></div>}</div>}
                    </div>
                    <div className='flex-1 flex xs:flex-col sm:flex-row gap-10 justify-between bg-white xs:p-20 sm:p-20 md:p-10 lg:p-20 rounded-lg'>
                        <div className='flex-1'>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">Company</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.company ? data?.company : '-'}</p></div>
                            </div>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">department</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.department ? data?.department : '-'}</p></div>
                            </div>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">Date Of Birth</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.dob ? DateFormat(data?.dob) : '-'}</p></div>
                            </div>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">Date Of Joining</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.doj ? DateFormat(data?.doj) : '-'}</p></div>
                            </div>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">gender</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.gender ? data?.gender : '-'}</p></div>
                            </div>
                        </div>
                        <div className='flex-1'>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">employee ID</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.employee_id ? data?.employee_id : '-'}</p></div>
                            </div>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">nationality</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.nationality ? data?.nationality : '-'}</p></div>
                            </div>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">section</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.section ? data?.section : '-'}</p></div>
                            </div>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">designation</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.designation ? data?.designation : '-'}</p></div>
                            </div>
                            <div className='flex-1 pb-3'>
                                <div><h2 className="capitalize !text-[1.2rem] text-[#0009]">location</h2></div>
                                <div><p className='text-[1.5rem]'>{data?.location ? data?.location : '-'}</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default Profile;
