import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';
import { DateFormat, deleteAllCookie } from 'helpers';
import { biddingDetails } from 'api/bidding';
import big from 'assets/media/bid.png';

const award_breakpoint = {
  bigDesktop: {
    breakpoint: { max: 4000, min: 1501 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1500, min: 1300 },
    items: 3
  },
  tab: {
    breakpoint: { max: 1300, min: 1000 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1000, min: 0 },
    items: 1
  }
};

export const Cars = (props) => {
  const [data, setData] = useState([]);
  const [errorPage, setErrorPage] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      biddingDetails().then(({ data }) => {
        setData(data);
        setErrorPage(false);
      }).catch((error) => {
        if (error[0] === 401) {
          deleteAllCookie();
        }
      });
    }, 2000);
  }, []);
  if (errorPage) {
    <div></div>;
  }
  return (
    <>
        <div className="w-full bg-[#ededed]" id="awards">
          <div className="sm:container mx-auto">
            <div className="flex justify-between sm:container xs:flex-col text-black xs:gap-4 sm:flex-row xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto z-[99999] py-[3rem]">
              <div className="xs:w-full sm:w-1/3  font-medium">
                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] uppercase ">
                  Open Bids
                </p>
              </div>
            </div>
          </div>
          {data.length !== 0
          ? <Carousel
            responsive={award_breakpoint}
            autoPlay={true}
            infinite={true}
            className="gap-10 flex pb-10"
            autoPlaySpeed={5000}
            arrows={true}
          >
            {data.map((value, i) => {
              const image = value?.banner_image?.url || DummyImage;
              return (
                <div key={i} className="flex flex-col xs:w-full sm:w-[95%] min-h-[400px]">
                  <div className="h-[300px] bg-no-repeat bg-center bg-cover relative" style={{ backgroundImage: `url("${image}")` }}>
                  </div>
                  <div className="bg-[#FDF4F5] text-black flex-1 text-black flex flex-col xs:gap-5 sm:gap-10 items-left p-6 gap-10">
                    <div className='xs:h-[200px] sm:h-[300px] xl:h-[150px] overflow-hidden flex flex-col gap-8'>
                      <div>
                        <h2 className="font-semibold">{value?.title}</h2>
                      </div>
                      <div>
                        {value?.start_amount && <p className='text-justify text-left text-xl font-extralight'><span className='text-[#908E91] font-light text-[22px]'>Start from </span>RO <span className='text-[#D42234]'>{value.start_amount}</span></p>}
                        {value?.close_date && <p className='text-left text-[#908E91] font-light text-[21px]'>Bidding closes on {DateFormat(value.close_date)}</p>}
                      </div>
                    </div>
                    <ButtonStyle title={'Bid now'} style={''} url={`/bidding/${value?.alias_name}`} />
                  </div>
                </div>
              );
            })}
          </Carousel>
          : <div className='sm:container mx-auto pb-10 xs:px-4'>
              <div className='flex gap-8 xs:flex-col md:flex-row'>
                <div className='xs:h-[400px] sm:h-[300px] md:h-[300px] lg:h-[500px] md:flex-[0.6_1_0%] lg:flex-1 w-full bg-no-repeat bg-center bg-cover relative' style={{ backgroundImage: `url(${ big })` }}></div>
                <div className='flex-1 flex flex-col justify-center items-center'><h1>Ohhh. No Open Bids Found</h1>
                <p>We currently don’t have any bids open. May be visit us after sometime to find any attractive bid.</p>
                </div>
              </div>
            </div>
          }
        </div>
    </>
  );
};
