import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { DummyImage } from 'config';
import 'react-multi-carousel/lib/styles.css';
import { fetchArticleByType } from 'api/article';
import { deleteAllCookie } from 'helpers';

const breakpoint = {
    desktop: {
        breakpoint: { max: 4000, min: 0 },
        items: 1
    }
};

export const Banner = () => {
    const [data, setData] = useState([]);
    const [errorPage, setErrorPage] = useState(true);
    const requestParams = [
        { name: 'bidding_banner', value: 'bidding_banner' },
    ];
    useEffect(() => {
        setTimeout(() => {
            fetchArticleByType(requestParams, false, 5, null, 'position').then(({ data }) => {
                setData(data);
                setErrorPage(false);
            }).catch((err) => {
                if (err[0] === 401) {
                    deleteAllCookie();
                }
                setErrorPage(true);
            });
        }, 2000);
    }, []);
    if (errorPage) {
        <div></div>;
    }
  return (
    <>
      <Carousel
        responsive={breakpoint}
        showDots={true}
        className="xs:bg-white"
        dotListClass="banner_dot_list"
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        arrows={false}
      >
        {data.map((value, i) => {
          const image = value?.banner_image?.url || DummyImage;
          return (
            <>
              <div
                key={i}
                className="sm:h-[650px] md:h-[700px] lg:h-[750px] xs:px-4 bg-white flex xs:flex-col sm:flex-row xs:gap-3 sm:gap-4 md:gap-8 lg:gap-12 2xl:gap-20"
              >
                <div
                  className="xs:h-[400px] sm:h-auto sm:flex-1 md:flex-[1.4_1_0%] lg:flex-[1.6_1_0%] bg-no-repeat xs:bg-cover sm:bg-cover bg-center"
                  style={{ backgroundImage: `url(${image})` }}
                ></div>
                <div className="flex-1 xl:pr-6 ">
                  <div className="relative xs:text-[1.4rem] sm:text-[1.8rem] md:text-[1.7rem] lg:top-0 font-medium xl:top-0 xs:right-[0%] sm:right-[36%] xs:pl-5 sm:py-2 sm:pl-5 md:py-10 md:pl-10 lg:pl-16 lg:py-[5rem] xl:pl-20 xl:py-[7rem] bg-white">
                    <h1>{value.title}</h1>
                  </div>
                  <div className="xs:p-4 sm:pr-0 md:pr-0 xs:w-full sm:w-[80%]">
                    <div>
                      <p className="text-justify leading-9" dangerouslySetInnerHTML={{ __html: value.short_description }} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </Carousel>
    </>
  );
};
