import React from 'react';
import { Redirect } from 'react-router-dom';
import CelebratingPeople from 'views/celebratingPeople';
import Home from 'views/home/index';
import HrBrief from 'views/hrBriefs';
import LeaderShip from 'views/leaderShip';
import Login from 'views/login';
import NewsEvent from 'views/newsAndEvents';
import Resource from 'views/resource';
import { getCookie } from 'helpers';
import Articles from 'views/articles';
// import ArticleDetail from 'views/articles/article';
import SignUp from 'views/login/signup';
// import Article from 'views/articles';
import { Templates } from 'views/articles/template';
import Profile from 'views/profile';
import GetInvolved from 'views/getInvolved';
import AddBlog from 'views/getInvolved/addBlog';
import Bidding from 'views/bidding';
import BiddingDetails from 'components/BiddingDetails';
import { BiddingErrorPage } from 'components/biddingErrorPage';

const protectedRoutes = [
    { path: '/home', component: Home },
    { path: '/leadership', component: LeaderShip },
    { path: '/celebrating-people', component: CelebratingPeople },
    { path: '/hr-brief', component: HrBrief },
    { path: '/news-events', component: NewsEvent },
    { path: '/resource-vault', component: Resource },
    { path: '/category/:id', component: Articles },
    { path: '/articles/:id', component: Templates },
    { path: '/profile', component: Profile },
    { path: '/get-involved', component: GetInvolved },
    { path: '/get-involved/add-blog', component: AddBlog },
    { path: '/bidding', component: Bidding },
    { path: '/bidding/not-found', component: BiddingErrorPage },
    { path: '/bidding/:id', component: BiddingDetails },
    // { path: '/', exact: true, component: () => <Redirect to='/home' }, },
];

const publicRoutes = [
    { path: '/forget-password', component: SignUp },
    { path: '/signup', component: SignUp },
    { path: '/login', component: Login },
    { path: '/', component: () => <Redirect to={(getCookie('auth_token')) ? '/home' : '/login'} /> }
];

export { protectedRoutes, publicRoutes };
