import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ArticleDetail = ({ data }) => {
  const [numPages, setNumPages] = useState(null);
  const breakpoint = {
    bigDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };
  const regex = /<pdfviewer ?[a-zA-Z=""]*[(http(s)?)://(www.)?a-zA-Z0-9@:%._+ -~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+-.~#?&//=]*)<\/pdfviewer>/gi;
  const pdfviewer = document
    .createRange()
    .createContextualFragment(
      (data?.article_detail?.content.match(regex) || []).join('')
    );
  const delContent = data?.article_detail?.content.replaceAll(regex, '');
  const nodeList = pdfviewer.querySelectorAll('pdfviewer');
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <div>
      <div className='sm:container px-4 mx-auto flex flex-col gap-10 py-10'>
        {data?.title_image?.url && (
          <div
            className='xs:h-[300px] sm:h-[400px] md:h-[550px] lg:h-[650px] xl:h-[700px] bg-no-repeat bg-center bg-cover'
            style={{
              backgroundImage: `url(${data?.title_image?.url})`,
            }}
          />
        )}
        <div className='flex flex-col gap-8 pb-20 border-b-8 border-[#D42234]'>
          <div>
            <h1
              className={
                data.article_type.alias_name === 'home_banner'
                  ? 'text-center text-4xl font-bold'
                  : 'text-4xl font-bold'
              }
            >
              {data?.title}
            </h1>
          </div>
          <div>
            <p
              className='text-justify'
              dangerouslySetInnerHTML={{ __html: delContent }}
            />
          </div>
          {nodeList &&
            [...nodeList]?.map((userItem, index) => {
              return (
                <Document
                  key={index}
                  file={userItem?.innerHTML}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <h2>{userItem?.attributes?.name?.value}</h2>
                  <Carousel
                    responsive={breakpoint}
                    autoPlay={userItem?.attributes?.scroll?.value || false}
                    infinite={false}
                    showDots={false}
                    shouldResetAutoplay={false}
                    key={index}
                  >
                    {Array.apply(null, Array(numPages))
                      .map((x, i) => i + 1)
                      .map((page, i) => (
                        <div key={i} className='flex'>
                          <Page
                            key={i}
                            pageNumber={page}
                            className='double-side flex-1'
                          />
                          {numPages === page
                          ? <></>
                          : <Page
                              key={i}
                              pageNumber={page + 1}
                              className='double-side flex-1'
                            />
                          }
                        </div>
                      ))}
                  </Carousel>
                </Document>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ArticleDetail;
