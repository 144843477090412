import { apiHost } from '../config.js';
import { getCookie, headers } from '../helpers';

export default async function ({ uri, method = 'GET', body }) {
  const isFormData = body instanceof FormData;
  return fetch(apiHost + uri, {
    method,
    body,
    headers: isFormData
      ? { Authorization: `API_KEY ${getCookie('auth_token')}` }
      : {
        ...headers()
      },
  }).then(async (response) => {
    if (response.ok) {
      try {
        return response.json();
      } catch (err) {
        return true;
      }
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}
