import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';

export const News = (props) => {
    const [data, setData] = useState([]);
    const [errorPage, setErrorPage] = useState(true);
    const requestParams = [
        { name: 'news', value: 'news' },
    ];

    useEffect(() => {
        setTimeout(() => {
            fetchArticleByType(requestParams, false, 1).then(({ data }) => {
                setData(data);
                setErrorPage(false);
            }).catch(() => setErrorPage(true));
        }, 2000);
    }, []);

    if (errorPage) {
        <div></div>;
    }

    return (
        <>
            {data.length > 0 &&
                <div className="w-full bg-[#EAEAEA] pb-10" id="new">
                    <div className="sm:container xs:px-4 sm:pxx-4 md:px-4 lg:px-4 mx-auto flex flex-col gap-6 text-black">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container sm:mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p cla ssName="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">news</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p
                                    onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}
                                >
                                    See All <span className="uppercase">news</span>
                                </p>
                            </div>
                        </div>
                        <div className="xs:flex-1 sm:flex-1 lg:flex-[0.8_1_0%] flex xl:flex-row xs:flex-col-reverse sm:flex-col-reverse gap-10 items-center">
                            <div className="flex-1 flex flex-col gap-10">
                                <div className="">
                                    <h1 className="text-black font-bold xs:w-full sm:w-[80%]">
                                        {data[0]?.title}
                                    </h1>
                                </div>
                                <div className="flex flex-1 flex-col gap-5">
                                    <div className='text-justify' dangerouslySetInnerHTML={{ __html: data[0]?.short_description }} />
                                </div>
                                <div className="flex flex-row justify-between xs:w-full">
                                    <ButtonStyle title={'read on'} style={'w-[90%]'} url={`/articles/${data[0]?.alias_name}`} />
                                    {/* <div className="flex flex-row gap-3 icon_unhidden text-[1.3rem] text-black">
                                <div className="flex flex-row gap-3 icon_hidden duration-1000">
                                    <i className="fab fa-linkedin hover:text-[#74777B]"></i>
                                    <i className="fab fa-twitter hover:text-[#74777B]"></i>
                                    <i className="fab fa-instagram hover:text-[#74777B]"></i>
                                    <i className="fab fa-facebook hover:text-[#74777B]"></i>
                                </div>
                                <i className="fa fa-share-alt" aria-hidden="true"></i>
                            </div> */}
                                </div>
                            </div>
                            {data.map((value, i) => {
                                return (
                                    <div key={i} div className="flex-1 flex xs:flex-col flex-row gap-4">
                                        <img src={value?.banner_image?.url || DummyImage} className="w-full" alt="..." />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div >
            }
        </>
    );
};
