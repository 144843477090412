import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfViewer = ({ data, multi }) => {
  const [numPages, setNumPages] = useState(null);
  const breakpoint = {
    bigDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const regex = /<pdfviewer>[http(s)?://(www./)?a-zA-Z0-9@:%._+ -~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+-.~#?&//=]*)<\/pdfviewer>/ig;
  const url = data?.article_detail?.content.match(regex).join('').replace('<pdfviewer>', '').replaceAll('</pdfviewer>', '');
  const urlpath = url.split('<pdfviewer>');
  const delContent = data?.article_detail?.content.replace(regex, '');
  console.log(multi, urlpath, 'link');
  return (
    <>
      <div className='sm:container mx-auto w-full py-8 flex flex-col gap-8'>
        <div><h2>{data?.title}</h2></div>
        <div><p dangerouslySetInnerHTML={{ __html: delContent }}/></div>
        {urlpath && urlpath.map((value, index) => (
        <Document
          key={index}
          file={value}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <h2>{value.split('/').slice(-1).join('').replace('.pdf', '').replaceAll('_', ' ').replace('(1)', '')}</h2>
            <Carousel responsive={breakpoint} autoPlay={false} infinite={false} showDots={false} shouldResetAutoplay={false} key={index}>
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page, i) => (
                <div key={i} className='flex'>
                  <Page key={i} pageNumber={page} className='flex-1 double-side' />
                  {numPages === page ? <></> : <Page key={i} pageNumber={page + 1} className='flex-1 double-side' />}
                </div>
              ))}
          </Carousel>
        </Document>
          ))}
      </div>
    </>
  );
};
