import config from './config.json';

export const apiHost = config.host;

export const DummyImage = 'https://docs-assets.katomaran.tech/images/leaderboard/home/2022/02/dummy.jpg';

const { reactAppVapidKey, ...rest } = config.firebase_config_keys;

export const firebaseConfigs = rest;

export const VapidKey = reactAppVapidKey;
