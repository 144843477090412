import React from 'react';

export const TempThree = ({ data }) => {
    return (
        <div className='w-full flex'>
            <div className='sm:container mx-auto px-4 border-b-8 py-10 border-[#D42234]'>
                {data?.title_image?.url && <div className='xs:w-full sm:w-[50%] sm:float-left p-3 pr-5'>
                    <img className='w-full' src={data.title_image.url} alt="" />
                </div>}
                <div className=''>
                    <h1>{data.title}</h1>
                    <p className='text-justify' dangerouslySetInnerHTML={{ __html: data?.article_detail?.content }} />
                </div>
            </div>
        </div>
    );
};
