import firebase from 'firebase/app';
import { firebaseConfigs, VapidKey } from 'config.js';
import 'firebase/messaging';

firebase.initializeApp(firebaseConfigs);
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

// const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = VapidKey;

export const getToken = async (setTokenFound) => {
    let currentToken = '';

    try {
        currentToken = await messaging.getToken({ vapidKey: publicKey });
        if (currentToken) {
            setTokenFound(true);
        } else {
            setTokenFound(false);
        }
    } catch (error) {
        console.log('An error occurred while retrieving token. ', error);
    }
    return currentToken;
};
