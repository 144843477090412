import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';

export const OtherAnnouncement = (props) => {
    const [errorPage, setErrorPage] = useState(true);
    const [announcement, setAnnouncement] = useState([]);
    const requestParams = [
        { name: 'announcement', value: 'announcement' }
    ];

    useEffect(() => {
        fetchArticleByType(requestParams, false, 3).then(({ data }) => {
            setAnnouncement(data);
            setErrorPage(false);
        }).catch(() =>
            setErrorPage(true)
        );
    }, []);

    if (errorPage) {
        return <div></div>;
    }
    return (
        <>
            {announcement?.[0]?.id &&
                <div className="w-full bg-white text-black" id="other">
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto">
                        <div className="flex justify-between xs:flex-col sm:flex-row sm:container mx-auto z-[99999] py-[3rem]">
                            <div className="xs:w-full sm:w-2/4 md:w-1/3">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">other announcements</p>
                            </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p
                                    onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}
                                >
                                    See All <span className="uppercase">other announcement</span>
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap gap-16 pb-16">
                            {announcement.map((value, i) => {
                                return (
                                    <div key={i} className="flex flex-col gap-10 sm:w-[25%] text-black xs:flex-[0_0_100%] sm:flex-[0_0_40%] md:flex-[0_0_45%] lg:flex-[0_0_29.33333%] xl:flex-[0_0_30.33333%] 2xl:flex-[0_0_30.33333%] hover:decoration-2">
                                        <div className="sm:h-[300px] md:h-[400px] lg:h-[400px]" style={{ backgroundImage: `url(${value?.banner_image?.url || DummyImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                                            {/* <img className="h-full w-full object-fit" src={value?.banner_images[0]?.image_url || DummyImage} alt="... " /> */}
                                        </div>
                                        <div className="flex-1 flex flex-col gap-4 sm:h-[150px]">
                                            <div className='xs:min-h-[250px] sm:min-h-[350px] md:min-h-[300px] lg:min-h-[250px] flex flex-col gap-4 text-black overflow-hidden'>
                                                <div className="">
                                                    <div>
                                                        <p className="text-2xl font-bold">{value.title}</p>
                                                    </div>
                                                </div>
                                                <div className="flex-1">
                                                    <div className="">
                                                        <h4 className='text-justify text-[1rem]'>{value.short_description}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <ButtonStyle title={'learn more'} style={'border-[#DF5E6B] text-[#DF5E6B] '} url={`/articles/${value?.alias_name}`} />
                                        </div>
                                    </div>
                                );
                            })
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
