import { fetchArticleByType } from 'api/article';
import { DateFormat } from 'helpers';
import React, { useEffect } from 'react';

export const RelatedArticle = ({ data, history, image }) => {
    useEffect(() => {
        setTimeout(() => {
            fetchArticleByType().then(({ data }) => {
                console.log(data);
            });
        });
    });
    return (
        <>
            <div className='container mx-auto py-10'>
                <div className='text-3xl font-bold'><h2>{data?.article_type?.name}</h2></div>
                <hr className='border-1 border-[#D42234]' />
                <div className='flex flex-row gap-10 pt-10'>
                    <div className='flex-1 flex flex-col gap-10 article_hover'>
                        <div className='flex-[0.9_1_0%] overflow-hidden'>
                            <img src={data?.title_image?.url} className='w-full article_img duration-700' alt='article' />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <div><p className='text-lg text-slate-500'>{DateFormat(data?.created_at, true)}</p></div>
                            <div className='flex flex-col gap-5'>
                                <div><h1 className='text-4xl article_head'>{data?.title}</h1></div>
                                <div>
                                    <p className='text-slate-600 text-justify' dangerouslySetInnerHTML={{ __html: data?.article_detail?.content }} /></div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-[0.4_1_0%]'>
                        <div className='flex flex-col gap-4 border-2 p-5 border-slate-200'>
                            <h1 className='text-2xl font-bold border-b-4 border-[#D42234] pb-2'>Related Article</h1>
                            <div className='flex flex-col gap-3 border-b-2 pb-2'>
                                <h1 className='text-xl font-bold hover:underline hover:text-[#D42234]'>10 days quick challange for boost your online visitors.</h1>
                                <p>16 Jan 2022</p>
                            </div>
                            <div className='flex flex-col gap-3 border-b-2 pb-2'>
                                <h1 className='text-xl font-bold hover:underline hover:text-[#D42234]'>10 days quick challange for boost your online visitors.</h1>
                                <p>16 Jan 2022</p>
                            </div>
                            <div className='flex flex-col gap-3 border-b-2 pb-2'>
                                <h1 className='text-xl font-bold hover:underline hover:text-[#D42234]'>10 days quick challange for boost your online visitors.</h1>
                                <p>16 Jan 2022</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
