import React, { Component } from 'react';
import Header from 'components/navBar';
import Footer from 'components/Footer';
import Banner from './banner';
import { SubHeader } from 'components/header';
import { fetchLeaderShip } from 'api/leaderShip';
import { Leaders } from 'components/empDetails';
import Vision from './vision';
import { CooFeedback } from './coo_feedback';
import { Loader } from 'components/loader';

class LeaderShip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            title: [
                { id: '#vision', value: 'الرؤيا و القيم <br /> Vision, Mission & Values' },
                { id: '#leader', value: 'القيادة <br /> Leadership' },
                { id: '#coo', value: 'صندوق الاقتراحات   <br /> Suggestion Box' },
            ],
            leaderShipData: [],
            messageFromCoo: [],
            loader: true
        };
    }

    componentDidMount() {
        document.title = 'Tawasul - Leadership';
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.leaderData();
        setTimeout(this.handleTimer, 3000);
    }

    handleTimer = () => {
        this.setState({ loader: false });
    }

    leaderData = () => {
        fetchLeaderShip().then(({ data }) => {
            this.setState({ leaderShipData: data });
        });
    }

    render() {
        const { leaderShipData, loader } = this.state;

        return (
            <div>
                {loader && <Loader />}
                <Header loader={loader} />
                <Banner />
                <SubHeader title={this.state.title} />
                <Vision />
                <div className="w-full bg-[#231F1F]" id="leader">
                    <Leaders data={leaderShipData} />
                </div>
                <CooFeedback />
                <Footer />
            </div>
        );
    }
}

export default LeaderShip;
