import React from 'react';
import Profile from 'assets/media/birth_img.png';

export const Comments = () => {
    return (
        <>
            <div className='sm:container px-4 mx-auto pt-4 flex flex-col gap-10'>
                <div><h1 className='text-3xl font-bold'>2 Comments</h1></div>
                <div className='flex flex-col gap-5 lg:w-[75%] xl:w-1/2'>
                    <div className='flex flex-row gap-5 border-b-2 pb-10'>
                        <div>
                            <div className='h-[80px] w-[80px] bg-black rounded-full bg-no-repeat bg-cover bg-center' style={{ backgroundImage: `url(${Profile})` }}>
                            </div>
                        </div>
                        <div className='flex flex-col gap-3'>
                            <div className='flex flex-col gap-1'>
                                <div><h2 className='text-xl font-bold'>Rashed ka.</h2></div>
                                <div><p className='text-[#989ca2]'>13 June, 2018, 7:30pm</p></div>
                            </div>
                            <div><p className='text-[#989ca2]'>One touch of a red-hot stove is usually all we need to avoid that kind of discomfort in future. The same true we experience the emotional sensation.</p></div>
                        </div>
                    </div>
                    <div className='flex flex-row gap-5 border-b-2 pb-10'>
                        <div>
                            <div className='h-[80px] w-[80px] bg-black rounded-full bg-no-repeat bg-cover bg-center' style={{ backgroundImage: `url(${Profile})` }}>
                            </div>
                        </div>
                        <div className='flex flex-col gap-3'>
                            <div className='flex flex-col gap-1'>
                                <div><h2 className='text-xl font-bold'>Rashed ka.</h2></div>
                                <div><p className='text-[#989ca2]'>13 June, 2018, 7:30pm</p></div>
                            </div>
                            <div><p className='text-[#989ca2]'>One touch of a red-hot stove is usually all we need to avoid that kind of discomfort in future. The same true we experience the emotional sensation.</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sm:container sm:mx-auto px-4 pb-10 flex flex-col gap-10 pt-4'>
                <div><h1 className='text-3xl font-bold'>Leave A Comment</h1></div>
                <div className='flex-1 flex flex-col gap-5'>
                    <div className='flex-1'>
                        <textarea placeholder='Your Comments*' className='xs:w-full sm:w-full md:w-full lg:w-[75%] xl:w-1/2 h-[100px] border-b-2 focus:outline-none'></textarea>
                    </div>
                    <div className='flex justify-end align-end lg:w-[75%] xl:w-1/2'>
                        <div className="relative xs:w-[60%] sm:w-[30%] border-t-2 text-center text-[#fff] bg-[#D42234] duration-150 comment-btn hover:cursor-pointer">
                            <button className="uppercase p-4 duration-150">post comments</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
