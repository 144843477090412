import { createBlog } from 'api/blog';
import Footer from 'components/Footer';
import Header from 'components/navBar';
import React, { Component } from 'react';

class AddBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            file: [],
            message: '',
            successMessage: false,
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleFileChange = (e) => {
        const { name, files } = e.target;
        this.setState({ [name]: files });
    }

    handleSumbit = () => {
        const formdata = new FormData();
        const { title, description, file } = this.state;
        formdata.append('title', title || '');
        formdata.append('description', description || '');
        Object.keys(file).map((value) => (formdata.append('attach_files[]', file[value])));
        createBlog(formdata).then(({ status }) => {
            if (status === true) {
                this.setState({ message: 'Post Submitted for Review....!' });
                this.fetchSuccessMessage();
                setTimeout(() => {
                    window.location.href = '/get-involved';
                }, 3000);
            }
        }).catch((err) => {
            console.log(err);
            this.setState({ message: err[1].data.join(' ') });
            this.fetchSuccessMessage();
        });
    }

    fetchSuccessMessage = () => {
        this.setState({ successMessage: true });
        setTimeout(() => {
            this.setState({ successMessage: false });
        }, 3000);
    }

    render() {
        const { title, description, message, successMessage } = this.state;
        const { handleChange, handleSumbit, handleFileChange } = this;
        return (
            <>
            <Header />
            <div className='lg:w-[80%] xl:w-[60%] 2xl:w-1/2 mx-auto p-6'>
                <div className='pb-6'>
                    <h2 className='text-[#D42234]'>Create Your Post Here</h2>
                    <p>Give us some details about the post that you would like to publish. The HR Admin will review you post and approve it, before it can appear for all our employees.</p>
                </div>
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-2'>
                        <label className='font-semibold text-xl'>Title</label>
                        <input type={'text'} value={title} name="title" placeholder='Enter Your Title' className='border p-2' onChange={handleChange}/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='font-semibold text-xl'>Message</label>
                        <textarea type={'text'} name="description" value={description} placeholder='Enter Your Message' className='border h-80 p-2' onChange={handleChange}/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='font-semibold text-xl'>Attach File</label>
                        <input type={'file'} name="file" multiple onChange={handleFileChange}/>
                    </div>
                    <div className='relative'>
                        {successMessage && <div className='absolute' id='successMessage'><p className='text-xl text-[#D42234]'>{message}</p></div>}
                    </div>
                    <div className='flex justify-end'>
                        <div className="relative rounded-sm border-t-2 text-center text-[#fff] bg-[#D42234] duration-150 comment-btn hover:cursor-pointer" onClick={handleSumbit}>
                            <button className="uppercase py-2 px-8 duration-150">Post</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </>
        );
    }
}

export default AddBlog;
