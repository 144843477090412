import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { protectedRoutes, publicRoutes } from 'routes/index';
import { getCookie } from 'helpers';

const PublicRoute = ({
  component: Component,
  path,
  ...rest
}) => {
  if (getCookie('auth_token')) {
    return <Redirect to='/home' />;
  } else {
    return <Route
      path
      {...rest}
      exact
      render={(props) => {
        return (
          <Component {...props} {...rest} />
        );
      }}
    />;
  }
};

const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  if (getCookie('auth_token')) {
    return (
      <Route
        exact
        {...rest}
        render={(props) => {
          return (
            <Component {...props} {...rest} />
          );
        }}
      />
    );
  } else {
    return <Redirect to='/' />;
  }
};

function App() {
  return (
    <Router>
      <Switch>
        {publicRoutes.map((route, idx) => (
          <PublicRoute
            exact
            path={route.path}
            component={route.component}
            key={idx}
          />
        ))}
        {protectedRoutes.map((route, idx) => {
          if (getCookie('auth_token')) {
            return (
              <PrivateRoute
                exact
                path={route.path}
                component={route.component}
                key={idx}
              />
            );
          }
          return <Redirect key={idx} to='/' />;
        }
        )}
      </Switch>
    </Router>
  );
}

export default App;
