import React, { Component } from 'react';
import Header from 'components/navBar';
import Footer from 'components/Footer';
import Feedback from 'components/Feedback';
import { Banner } from './banner';
import { CustomerService } from './customerService';
import { SalesServiceProcess } from './salesServiceProcess';
import { SafetyTips } from './safetyTips';
import { ItHelpDesk } from './ITHelpDesk';
import { Office365Training } from './office365Training';
import { Others } from './others';
import { SubHeader } from 'components/header';
import { Loader } from 'components/loader';

class Resource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: [
                { id: '#customer', value: 'خدمة العملاء 101 <br /> Customer Service 101' },
                { id: '#sales-service', value: 'الخطوات المتبعة للمبيعات و الخدمة <br /> Sales & Services Process' },
                { id: '#safety-tips', value: 'نصائح أمنية <br /> Safety Tips' },
                { id: '#it-help-desk', value: 'خدمة تقنية المعلومات <br /> IT Help Desk' },
                { id: '#office365-training', value: 'برامج تدريبية لميكروسوفت <br /> Office365 Training' }
            ],
            calender: [],
            loader: true
        };
    }

    componentDidMount() {
        document.title = 'Tawasul - Resource Vault';
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setTimeout(this.handleTimer, 3000);
    }

    handleTimer = () => {
        this.setState({ loader: false });
    }

    render() {
        const { loader } = this.state;
        return (
            <div>
                {loader && <Loader />}
                <Header loader={loader} />
                <Banner />
                <SubHeader title={this.state.title} />
                <CustomerService {...this.props} />
                <SalesServiceProcess {...this.props} />
                <SafetyTips {...this.props} />
                <ItHelpDesk {...this.props} />
                <Office365Training {...this.props} />
                <Others {...this.props} />
                <Feedback />
                <Footer />
            </div>
        );
    }
}
export default Resource;
