import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';
import { DateFormat, articleNew } from 'helpers';
class CelebratingPeople extends Component {
  constructor(props) {
    super(props);
    this.requestParams = [
      { name: 'celebrating_people', value: 'celebrating_people' },
    ];
    this.state = {
      data: [],
      errorPage: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    fetchArticleByType(this.requestParams, false, 3)
      .then(({ data }) => {
        this.setState({ data, errorPage: false });
      })
      .catch(() => this.setState({ errorPage: true }));
  };

  render() {
    const breakpoint = {
      desktop: {
        breakpoint: { max: 4000, min: 768 },
        items: 2,
      },
      tab: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
      },
    };

    if (this.state.errorPage) {
      return <div></div>;
    }
    const { data } = this.state;

    return (
      <React.Fragment>
        {data?.[0]?.id && (
          <div className="relative">
            <div className="sm:container mx-auto">
              <div className="absolute xs:px-4 sm:px-4 md:px-4 lg:px-4 xs:gap-[1rem] sm:gap-[0rem] xs:top-[45px] sm:top-[65px] md:top-[65px] lg:top-[65px] xl:top-[60px] flex xs:flex-col md:flex-row justify-between sm:container mx-auto z-[99999]">
                <div className="xs:w-full sm:w-1/3 lg:text-[#8E9092] xs:text-black sm:text-[#B1B3B5] font-medium items-center">
                  <p
                    className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] uppercase"
                    style={{ fontFamily: 'Futura' }}
                  >
                    celebrating people
                  </p>
                </div>
                <div className="lg:w-[36%] xs:text-left text-right xl:w-1/3 sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline font-light hover:cursor-pointer sm:text-[#B1B3B5] lg:text-[#8E9092]">
                  <p
                    onClick={() =>
                      this.props.history.push({
                        pathname: `/category/${this.requestParams[0].name}`,
                        state: { fetchValues: this.requestParams },
                      })
                    }
                    className="hover:underline font-light"
                  >
                    See All{' '}
                    <span className="uppercase">celebrating people</span>{' '}
                    Stories
                  </p>
                </div>
              </div>
            </div>
            <Carousel
              responsive={breakpoint}
              autoPlay={true}
              infinite={true}
              howDots={false}
              arrows={false}
              className="flex"
              autoPlaySpeed={5000}
            >
              {data.map((value, i) => {
                const image =
                  value?.banner_image?.url || DummyImage;
                const new_article = articleNew(value.published_at);
                return (
                  <div
                    key={i}
                    className={`xs:min-h-[200px] sm:min-h-[800px] xs:px-4 flex flex-col gap-0 pb-8 pt-36 ${i % 2 === 0 ? 'bg-[#dfe4ea]' : 'bg-[#f1f2f6]'
                      }`}
                  >
                    <div className="flex-1 xs:pr-[1rem] sm:pr-[2rem] md:pr-[3rem] lg:pr-[5rem] xl:pr-[5rem] 2xl:pr-[8rem] xs:pl-[0%] pl-[20%]">
                      <div
                        className="xs:h-[250px] sm:h-[300px] md:h-[220px] lg:h-[250px] xl:h-[300px] 2xl:h-[400px] bg-no-repeat bg-center bg-cover object-cover relative"
                        style={{
                          backgroundImage: `url(${image === 'undefined' ? DummyImage : image
                            })`,
                        }}
                      >
                        <div className={`new ${new_article ? '' : 'hidden'}  `}>
                          <div className="new-clip">
                            <p className='absolute top-[-30px] left-0 rotate-45 text-white font-bold'>NEW</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 xs:pr-[1rem] sm:pl-[20%] pt-10 pb-1">
                      <p />
                    </div>
                    <div className="sm:pr-[2rem] md:pr-[3rem] lg:pr-[5rem] xl:pr-[5rem] 2xl:pr-[8rem] xs:pl-[0%] pl-[20%] flex flex-col flex-1 gap-6">
                      <div className="xs:min-h-[450px] sm:min-h-[350px] md:min-h-[600px] lg:min-h-[500px] xl:min-h-[400px] 2xl:min-h-[300px] flex flex-col gap-3">
                        <p>{DateFormat(value.published_at)}</p>
                        <div className="font-semibold font-medium">
                          <h2>{value.title}</h2>
                        </div>
                        <div>
                          <p className="text-justify">
                            {value.short_description}
                          </p>
                        </div>
                      </div>
                      <ButtonStyle
                        title={'read on'}
                        style={'mt-8'}
                        url={`/articles/${value?.alias_name}`}
                      />
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default CelebratingPeople;
