import React, { useEffect, useState } from 'react';
import { DummyImage } from 'config';
import { fetchArticleByType } from 'api/article';
import { ButtonStyle } from 'components/button';

export const SafetyTips = (props) => {
    const [errorPage, setErrorPage] = useState(true);
    const [data, setData] = useState([]);
    const requestParams = [
        { name: 'safety_tips', value: 'safety_tips' }
    ];

    useEffect(() => {
        fetchArticleByType(requestParams, false, 1).then(({ data }) => {
            setData(data);
            setErrorPage(false);
        }).catch(() => setErrorPage(true));
    }, []);

    if (errorPage) {
        <div></div>;
    }

    return (
        <>
            {data?.[0]?.id &&
                <div className="w-full bg-[#908E8E] text-black" id="safety-tips">
                    <div className="sm:container xs:p-4 sm:p-4 lg:p-4 xl:p-4 mx-auto">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="text-white sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">Safety Tips</p> </div>
                            <div className="text-white xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}>See All <span className="uppercase">Safety Tips</span></p></div>
                        </div>
                        <div className="flex flex-col gap-10 pb-10">
                            {data?.map((value, i) => {
                                return (
                                    <div key={i} className="text-white flex-1 flex xs:flex-col-reverse sm:flex-col-reverse md:flex-row xs:gap-5 sm:gap-5 lg:gap-0 xl:gap-0 items-center">
                                        <div className="flex flex-1 flex-col gap-10">
                                            <div className="flex-1">
                                                <h1 className="pr-8 uppercase font-bold">{value?.title}</h1>
                                            </div>
                                            <div className='flex-1 text-justify xs:w-full sm:w-[80%]' dangerouslySetInnerHTML={{ __html: value?.short_description }} />
                                            <ButtonStyle title={'read on'} style={'border-[#DF5E6B] text-[#DF5E6B] w-4/5'} url={`/articles/${value?.alias_name}`} />
                                        </div>
                                        <div className="flex-1">
                                            <img src={value?.banner_image?.url || DummyImage} alt="..." />
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            }
        </>
    );
};
