import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';
import Carousel from 'react-multi-carousel';
import { DummyImage } from 'config';
import { ButtonStyle } from 'components/button';

const award_breakpoint = {
  bigDesktop: {
    breakpoint: { max: 4000, min: 1501 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1500, min: 1100 },
    items: 3,
  },
  tab: {
    breakpoint: { max: 1100, min: 640 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
  },
};

export const PromotionRecruits = (props) => {
  const [errorPage, setErrorPage] = useState(true);
  const [promotions, setPromotions] = useState([]);
  const requestParams = [
    { name: 'promotions', value: 'promotions' },
    { name: 'new_recruits', value: 'new_recruits' },
  ];

  useEffect(() => {
    fetchArticleByType(requestParams, false, 6)
      .then(({ data }) => {
        setPromotions(data);
        setErrorPage(false);
      })
      .catch(() => setErrorPage(true));
  }, []);

  if (errorPage) {
    return <div></div>;
  }

  return (
    <>
      {promotions?.[0]?.id && (
        <div className='w-full bg-[#ededed]' id='promotions'>
          <div className='sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 xl:px-4 mx-auto'>
            <div className='flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-black'>
              <div className='xs:w-full sm:w-1/3'>
                <p className='sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase'>
                  promotions & new recruits
                </p>{' '}
              </div>
              <div className='xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:cursor-pointer xs:text-left text-right'>
                <p>
                  <span
                    onClick={() =>
                      props.history.push({
                        pathname: `/category/${requestParams[1].name}`,
                        state: { fetchValues: requestParams },
                      })
                    }
                    className='hover:underline'
                  >
                    See All <span className='uppercase'>new recruits</span> &
                  </span>{' '}
                  <span
                    onClick={() =>
                      props.history.push({
                        pathname: `/category/${requestParams[0].name}`,
                        state: { fetchValues: requestParams },
                      })
                    }
                    className='hover:underline uppercase'
                  >
                    promotions
                  </span>
                </p>
              </div>
            </div>
          </div>
          <Carousel
            autoPlaySpeed={5000}
            responsive={award_breakpoint}
            arrows={false}
            autoPlay={true}
            infinite={true}
            className='sm:container mx-auto gap-10 flex xs:pb-0 sm:pb-10'
          >
            {promotions.map((value, i) => {
              const image = value?.banner_image?.url || DummyImage;
              return (
                <div
                  key={i}
                  className='flex flex-col xs:w-full sm:w-[95%] 2xl:min-h-[500px]'
                >
                  <div
                    className='xs:h-[300px] sm:h-[300px] md:h-[300px] 2xl:h-[300px] w-full bg-no-repeat bg-center bg-cover'
                    style={{ backgroundImage: `url(${image})` }}
                  ></div>
                  <div className='bg-black flex-1 text-white flex flex-col p-8 gap-10'>
                    <div className='xs:h-[150px] sm:h-[150px] md:h-[200px] lg:h-[100px] xl:h-[150px] 2xl:h-[180px] overflow-hidden flex flex-col gap-4'>
                      <div>
                        <h4 className='font-semibold text-xl'>
                          {value?.title}
                        </h4>
                      </div>
                      <div>
                        <p className='text-justify text-left pr-4'>
                          {value?.short_description}
                        </p>
                      </div>
                    </div>
                    <ButtonStyle
                      title={'congratulate'}
                      style={''}
                      url={`/articles/${value?.alias_name}`}
                    />
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
};
