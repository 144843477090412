
import React from 'react';
import { titleize, path, DateFormat } from 'helpers';
import { DummyImage } from 'config';

export const ArticleList = ({ data, history }) => {
  return (
    <React.Fragment>
      <div className="container mx-auto py-10 flex flex-col gap-5">
        <div className="w-[80%] mx-auto">
          <div className="text-3xl font-bold pb-5">
            <h2 className="capitalize">
              {path(titleize(window.location.pathname))}
            </h2>
          </div>
          {data.length !== 0
            ? (
            <div>{data.map((value, i) => (
              <div key={i} className="flex flex-col pt-6">
                <div className="flex flex-row gap-5 pb-8">
                  <div
                    className="xs:h-[300px] sm:h-[300px] md:h-[300px] lg:h-[400px] md:flex-[0.6_1_0%] xl:h-[300px] bg-no-repeat bg-center bg-cover xl:flex-[0.6_1_0%]"
                    style={{
                      backgroundImage: `url(${value?.banner_image?.url ||
                        DummyImage})`,
                    }}
                  ></div>
                  <div className="flex-1 flex flex-col gap-4">
                    <div>
                      <h2
                        onClick={() =>
                          history.push({
                            pathname: `/articles/${value?.alias_name}`,
                          })
                        }
                        className="font-bold hover:cursor-pointer"
                      >
                        {value?.title}
                      </h2>
                    </div>
                    <div>
                      <p
                        className="text-[#0009] text-justify"
                        dangerouslySetInnerHTML={{
                          __html: value?.short_description,
                        }}
                      />
                    </div>
                    <div>
                      <p className="text-[#0009]">
                        {DateFormat(value?.published_at)}
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="border-1 border-[#D42234]" />
              </div>
              ))}
            </div>
          )
          : (
            <div className='flex item-center justify-center py-20'>
              <p className='font-medium text-2xl'>There is no articles under this category!</p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
