import Header from 'components/navBar';
import React, { useEffect, useState } from 'react';
import { ArticleList } from './article_list';
import { fetchArticleByType } from 'api/article';
import { Loader } from 'components/loader';
import { path, deleteAllCookie } from 'helpers';
import { DummyImage } from 'config';
import Footer from 'components/Footer';

const Article = (props) => {
    const [data, setData] = useState([]);
    const [errorPage, setErrorPage] = useState(true);
    const [loader, setLoader] = useState(true);
    const [image, setImage] = useState(DummyImage);

    const requestParams = [
        { name: path(window.location.pathname), value: path(window.location.pathname) }
    ];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setTimeout(() => {
            fetchArticleByType(requestParams, false, 20).then(({ data }) => {
                setData(data);
                setErrorPage(false);
                setImage(
                    data[0]?.title_image?.url === undefined
                        ? DummyImage
                        : data[0]?.title_image?.url
                );
                handleTimer();
            }).catch((error) => {
                if (error[0] === 401) {
                    deleteAllCookie();
                } setErrorPage(true);
            });
        }, 2000);
    }, []);
    if (errorPage) {
        <div></div>;
    }

    const handleTimer = () => {
        setLoader(false);
    };

    const listProps = {
        data,
        history: props.history,
        image
    };

    return (
        <div>
            {loader && <Loader />}
            <Header loader={loader} />
            <ArticleList {...listProps} />
            <Footer />
        </div>
    );
};
export default Article;
