import React, { useState, useEffect } from 'react';
import { getToken } from '../../firebaseInit.js';
import { addFcmToken } from 'api/fcm.js';

const Notifications = () => {
    const [isTokenFound, setTokenFound] = useState(false);
    useEffect(() => {
        let data;
        async function tokenFunc() {
            data = await getToken(setTokenFound);
            if (data) {
                addFcmToken({ token: data }).then((response) => {
                    console.log(response.message, isTokenFound);
                }).catch(err => console.log(err));
            }
            return data;
        }

        tokenFunc();
    }, [setTokenFound]);

    return <></>;
};

Notifications.propTypes = {};

export default Notifications;
