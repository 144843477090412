import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';
import { DummyImage } from 'config';

export const Meeting = (props) => {
    const [errorPage, setErrorPage] = useState(true);
    const [data, setData] = useState([]);
    const requestParams = [
        { name: 'meeting', value: 'meeting' },
    ];

    useEffect(() => {
        setTimeout(() => {
            fetchArticleByType(requestParams, false).then(({ data }) => {
                setData(data);
                setErrorPage(false);
            }).catch(() => setErrorPage(true));
        }, 2000);
    }, []);
    if (errorPage) {
        <div></div>;
    }

    return (
        <>
            {data?.[0]?.id &&
                <div className="w-full" id="meeting">
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">Meeting</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}>
                                    See All <span className="uppercase">meeting</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto flex flex-row flex-wrap gap-5 py-10">
                        {data.map((value, i) => (
                            <div key={i} className="xs:flex-[0_0_100%] md:flex-[0_0_100%] lg:flex-[0_0_49%] flex xs:flex-col md:flex-col lg:flex-row gap-14 py-2 overflow-hidden" style={{ boxShadow: 'whitesmoke 0px 0px 20px 10px' }}>
                                <div className="bg-no-repeat bg-center bg-cover xs:h-[200px] md:h-[300px] lg:h-[200px] lg:w-[400px]" style={{ backgroundImage: `url(${value?.banner_image?.url || DummyImage})`, transform: 'scale(1.3) rotate(3deg)' }}></div>
                                <div className="px-2 flex flex-col gap-3">
                                    <div>
                                        <h2 className="font-bold">{value?.title}</h2>
                                    </div>
                                    <div className="h-2 w-14 bg-[#D42234] rounded-lg"></div>
                                    <div>
                                        <p className='text-justify'>{value?.short_description}</p>
                                    </div>
                                    <div>
                                        <p className="hover:underline hover:decoration-[#D42234] text-[#D42234] text-right pr-4 hover:cursor-pointer">Read On</p></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </>
    );
};
