import React, { Component } from 'react';
import Header from 'components/navBar';
import Footer from 'components/Footer';
import Feedback from 'components/Feedback';
import { Gathering } from './gathering';
import { Meeting } from './meeting';
import { Banner } from './banner';
import { News } from './news';
import { Events } from './events';
import { SubHeader } from 'components/header';
import { NewsOthers } from './others';
import { Loader } from 'components/loader';
class NewsEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: [
                { id: '#news', value: ' الاخبار <br /> News' },
                { id: '#events', value: 'الاحداث <br /> Events' },
                { id: '#gathering', value: 'الاحتفالات <br /> Gathering' },
            ],
            calender: [],
            loader: true
        };
    }

    componentDidMount() {
        document.title = 'Tawasul - News';
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setTimeout(this.handleTimer, 3000);
    }

    handleTimer = () => {
        this.setState({ loader: false });
    }

    render() {
        const { loader } = this.state;
        return (
            <div>
                {loader && <Loader />}
                <Header loader={loader} />
                <Banner />
                <SubHeader title={this.state.title} />
                <News {...this.props} />
                <Events {...this.props} />
                <Gathering {...this.props} />
                <Meeting {...this.props} />
                <NewsOthers {...this.props} />
                <Feedback />
                <Footer />
            </div>
        );
    }
}

export default NewsEvent;
