import React, { Component } from 'react';
import Header from 'components/navBar';
import Footer from 'components/Footer';
import Feedback from 'components/Feedback';
import { SubHeader } from 'components/header';
import { Loader } from 'components/loader';
import { PromotionRecruits } from './promotion_recruits';
import { OpenPosition } from './open_position';
import { OtherAnnouncement } from './other_announcement';
import { Banner } from './banner';
import { HolidaysAndTraining } from './holidays_events';

class HrBrief extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            title: [
                { id: '#promotions', value: 'الترقيات و الموظفين الجدد <br /> Promotions & New Recruits' },
                { id: '#positions', value: 'وظائف داخلية <br /> Internal Open Positions' },
                // { id: '#holidays', value: 'Holidays & Training Calender' },
                { id: '#other', value: 'نشرات اخرى <br /> Other Announcements' },
            ],
            promotions: [],
            calender: [],
            loader: true,
        };
    }
    componentDidMount() {
        document.title = 'Tawasul - HR Briefs';
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setTimeout(this.handleTimer, 3000);
    }

    handleTimer = () => {
        this.setState({ loader: false });
    }

    render() {
        const { loader } = this.state;
        return (
            <div>
                {loader && <Loader />}
                <Header loader={loader} />
                <Banner />
                <SubHeader title={this.state.title} />
                <PromotionRecruits {...this.props} />
                <OpenPosition {...this.props} />
                <HolidaysAndTraining />
                <OtherAnnouncement {...this.props} />
                <Feedback />
                <Footer />
            </div>
        );
    }
}
export default HrBrief;
