import React, { useEffect, useState } from 'react';
import { DummyImage } from 'config';
import { fetchArticleByType } from 'api/article';
import { ButtonStyle } from 'components/button';

export const NewsEvents = (props) => {
    const [data, setData] = useState([]);
    const [errorPage, setErrorPage] = useState(true);

    const requestParams = [
        { name: 'news', value: 'news' },
    ];
    useEffect(() => {
        setTimeout(() => {
            fetchArticleByType(requestParams, true).then(({ data }) => {
                setData(data);
                setErrorPage(false);
            }).catch(() => setErrorPage(true));
        }, 2000);
    }, []);

    if (errorPage) {
        return <div></div>;
    }
    return (
        <>
            {data?.[0] &&
                <div className="min-h-[100px] w-full mt-10 bg-[#231F1F] 2xl:news_background pb-10">
                    <div className="flex xs:flex-col xs:gap-[.1rem] sm:gap-[0rem] sm:flex-row justify-between px-4 sm:container sm:mx-auto z-[99999]  py-[3rem]">
                        <div className="xs:w-full sm:w-1/3 flex justify-between text-[#8E9092] font-medium"><p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] uppercase">News & events</p></div>
                        <div className="lg:w-[36%] xs:text-left text-right xl:w-1/3 sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline font-light hover:cursor-pointer text-[#8E9092]">
                            <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}>See All <span className="uppercase font-light">news & events</span></p>
                        </div>
                    </div>
                    <div className="flex flex-col xs:px-4 sm:px-4 md:px-4 lg:px-4 sm:container sm:mx-auto text-white xs:gap-0 sm:gap-10">
                        <div className="flex-1 xs:flex-col  sm:flex-col flex sm:items-center lg:flex-row md:flex-row pb-[3rem] xs:gap-10 sm:gap-14 md:gap-14 lg:gap-14 2xl:gap-16">
                            <div className="md:flex-1 lg:flex-[1_1_0%] 2xl:flex-[1.1_1_0%]">
                                <img className="w-full h-full" src={data[0]?.title_image?.url || DummyImage} alt="..." />
                            </div>
                            <div className="xs:flex-1 md:flex-1 lg:flex-[0.7_1_0%] flex flex-col xs:gap-10 sm:gap-12 md:gap-12 lg:gap-12 2xl:gap-16">
                                <div className="md:flex-1 lg:flex-[0.7_1_0%] flex flex-col xs:gap-10 sm:gap-12 md:gap-12 lg:gap-12 2xl:gap-16">
                                    <div className="font-medium">
                                        <h1>{data[0]?.title}</h1>
                                    </div>
                                    <div className='text-justify' dangerouslySetInnerHTML={{ __html: data[0]?.short_description }} />
                                    <ButtonStyle title={'find out more'} style={'md:w-full lg:w-full border-[#A27478] text-[#A27478]'} url={`/articles/${data[0]?.alias_name}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
