import React, { Component } from 'react';
import { SubHeader } from 'components/header';
import Header from 'components/navBar';
import { Banner } from './banner';
import { Loader } from 'components/loader';
// import { SpecialDistinction } from './special_distinction';
import { Awards } from './awards';
import { Birthday } from './birthday';
import Feedback from 'components/Feedback';
import Footer from 'components/Footer';
// import { SpecialOccasion } from './special_occasion';

class CelebratingPeople extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            title: [
                { id: '#awards', value: ' الجوائز <br /> Awards & Recognition' },
                { id: '#birthday', value: 'اعياد الميلاد,و المناسبات السنوية و الوداع <br /> Birthdays, Anniversaries & Farewells' },
                // { id: '#occasions', value: 'Special Occasions' },
            ],
        };
    }

    componentDidMount() {
        document.title = 'Tawasul - Celebrating People';
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setTimeout(this.handleTimer, 3000);
    }

    handleTimer = () => {
        this.setState({ loader: false });
    }

    render() {
        const { loader } = this.state;
        return (
            <div>
                {loader && <Loader />}
                <Header loader={loader} />
                <Banner />
                <SubHeader title={this.state.title} />
                {/* <SpecialDistinction {...this.props} /> */}
                <Awards {...this.props} />
                <Birthday />
                {/* <SpecialOccasion {...this.props} /> */}
                <Feedback />
                <Footer />
            </div>
        );
    }
}
export default CelebratingPeople;
