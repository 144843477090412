import React, { useEffect, useState } from 'react';
import { DummyImage } from 'config';
import { fetchArticleByType } from 'api/article';
import { ButtonStyle } from 'components/button';

export const CustomerService = (props) => {
    const [errorPage, setErrorPage] = useState(true);
    const [data, setData] = useState([]);
    const requestParams = [
        { name: 'customer_service', value: 'customer_service' }
    ];

    useEffect(() => {
        fetchArticleByType(requestParams, false, 1).then(({ data }) => {
            setData(data);
            setErrorPage(false);
        }).catch(() => setErrorPage(true));
    }, []);

    if (errorPage) {
        <div></div>;
    }

    return (
        <>
            {data?.[0]?.id &&
                <div className="w-full bg-[#D5D5D5] 2xl:news_background" id="customer">
                    <div className="flex flex-col sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto text-black pb-20 gap-10">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container sm:mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">Customer Service 101</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p onClick={() => props.history.push({ pathname: `/category/${requestParams[0].name}`, state: { fetchValues: requestParams } })}>See All <span className="uppercase">Customer Service 101</span></p></div>
                        </div>
                        <div className="flex-[0.8_1_0%] xs:flex-col sm:flex-col flex items-center lg:flex-row-reverse md:flex-col xs:gap-10 sm:gap-14 md:gap-14 lg:gap-14 2xl:gap-16">
                            <div className="md:flex-1 lg:flex-[1_1_0%] 2xl:flex-[1_1_0%]">
                                <img className="w-[70%] mx-auto object-fit" src={data[0]?.banner_image?.url || DummyImage} alt="..." />
                            </div>
                            <div className="flex-1 flex flex-col xs:gap-10 sm:gap-12 md:gap-12 lg:gap-12 2xl:gap-16">
                                <div className="font-bold sm:w-full lg:w-[80%]"><h1>{data[0]?.title}</h1></div>
                                <div className="flex flex-col gap-5">
                                    <p className='text-justify'>{data[0]?.short_description}</p>
                                    {/* <p>duis ultricies tellus. Ante sed blandit metus vehicula id. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut sit amet condimentum velit, a dictum tellus. Don.</p> */}
                                </div>
                                <ButtonStyle title={'read on'} style={'w-[50%]'} url={`/articles/${data[0]?.alias_name}`} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
