import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getRecentData } from 'api/article';
import { biddingFeatureDetails } from 'api/bidding';
// import { ButtonStyle } from 'components/button';

const breakpoint = {
    bigDesktop: {
        breakpoint: { max: 4000, min: 0 },
        items: 1
    }
};

const onClick = (url) => {
    window.location.href = url;
};

const RecentEvents = (props) => {
    const [data, setData] = useState([]);
    const [errorPage, setErrorPage] = useState(true);
    const [showArrow, setShowArrow] = useState(true);
    const requestParams = [
        { name: 'awards_recognition', value: 'awards_recognition' }
    ];
    useEffect(() => {
        setTimeout(async() => {
            let recentData = [];
            await biddingFeatureDetails({ perPage: 1 }).then(({ data }) => {
                recentData = data;
                setErrorPage(false);
            }).catch(() => setErrorPage(true));
            await getRecentData(requestParams, false, 5).then(({ data }) => {
                setData([...data, ...recentData]);
                setErrorPage(false);
            }).catch(() => setErrorPage(true));
        }, 2000);
        const top = window.screen.width > 639;
        setShowArrow(top);
    }, []);
    if (errorPage) {
        <div></div>;
    }
    return (
        <div className='bg-[#d42234] w-full'>
            <div className="flex xs:flex-col xs:gap-[.1rem] sm:gap-[0rem] sm:flex-row justify-between sm:container sm:mx-auto px-4 z-[99999]  py-[2rem]">
                <div className="xs:w-full sm:w-1/3 flex justify-between text-white font-medium"><p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] uppercase">Latest news</p></div>
            </div>
            <Carousel
                responsive={breakpoint}
                autoPlay={true}
                infinite={true}
                className="gap-10 flex pb-14 bg-[#d42234] recent-events"
                showDots={false}
                autoPlaySpeed={5000}
                arrows={showArrow}
                dotListClass="latest_dot_list"
                >
                {data.map((value, i) => {
                    const path_type = value?.article_type ? 'articles' : 'bidding';
                    return <>
                        <div key={i} className="min-h-[150px] text-white px-4 sm:container sm:mx-auto">
                            <div className='flex flex-col'>
                                <div>
                                    <h2>{value.title}</h2>
                                </div>
                                <div className='flex xs:flex-col sm:flex-row'>
                                    <div className='flex-1'>
                                        <div className='w-[95%]' dangerouslySetInnerHTML={{ __html: value?.short_description }} />
                                    </div>
                                    <div className='flex-[0.3_1_0%] xs:pt-4'>
                                        <button className='uppercase text-white border-white border-2 px-8 py-2 hover:text-[#d42234] hover:bg-white' onClick={() => onClick(`/${path_type}/${value?.alias_name}`)}>Read on</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>;
                })}
            </Carousel>
        </div>
    );
};
export default RecentEvents;
