import makeRequest from './index';

export const biddingDetails = () => {
    return makeRequest({
        uri: '/v1/bids?status[]=published&created_at DESC',
    });
};

export const biddingFeatureDetails = (requestParams) => {
    return makeRequest({
        uri: `/v1/bids?status[]=published&per_page=${requestParams.perPage}&featured=true&order=created_at DESC`,
    });
};

export const biddingShowDetails = (id) => {
    return makeRequest({
        uri: `/v1/bids/${id}/alias`,
    });
};

export const biddingSubmission = (id, amount) => {
    return makeRequest({
        uri: `/v1/bids/${id}/bidding`,
        method: 'POST',
        body: JSON.stringify(amount),
    });
};
