import React from 'react';
import { Component } from 'react/cjs/react.production.min';
import { setCookie } from 'helpers';
// import zubairLogo from 'assets/media/zubair-logo.png';
import { sendOTP, verifyOTP, setPassword } from 'api/authentication';
import { RightSide } from './rightSideCard';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                phone: '',
                password: null,
                otp_token: '',
                confirm_password: null,
            },
            submitBtnEnable: true,
            otpField: false,
            errorMessage: false,
            errorStatus: false,
            sendOtpBtn: false,
            verifyBtn: false,
            confirmPasswrdIcon: 'far fa-eye-slash',
            iconClassName: 'far fa-eye-slash',
            passwordType: 'password',
            confirmPasswordType: 'password',
            userName: ''
        };
    }

    onVerifyOTP = () => {
        const { data } = this.state;
        if (!data.otp_token) {
            this.setState({ errorMessage: 'Enter Valid Token', errorStatus: false });
            return;
        }
        this.setState({ verifyBtn: true });
        verifyOTP({ phone: data.phone, otp_token: data.otp_token }).then((response) => {
            if (response.status) { setCookie('auth_token', response.data.auth_token, 30); }
            // setCookie('email', data.email, 30);
            // setCookie('name', data.name, 30);
            this.setState({ setPassword: response.status, verifyBtn: false, userName: response.data.name });
        }).catch(error => {
            this.setState({ errorMessage: error[1].message || 'Something Went Wrong', errorStatus: false, verifyBtn: false });
        });
    };

    onSendOTP = () => {
        const { data } = this.state;
        if (!data.phone) {
            this.setState({ errorMessage: 'Verify Above all fields are filled', errorStatus: false });
            return;
        }
        this.setState({ sendOtpBtn: true });
        sendOTP({ phone: data.phone }).then((response) => {
            this.setState({ otpField: response.status });
        }).catch(error => {
            this.setState({ errorMessage: error[1].message || (error[1].data && error[1].data[0]), errorStatus: false, sendOtpBtn: false });
        });
    }

    onSetPassword = () => {
        const { data } = this.state;
        if (!data.password || !data.confirm_password) {
            this.setState({ errorMessage: 'Verify Above all fields are filled', errorStatus: false });
            return undefined;
        }
        if (data.password !== data.confirm_password) {
            this.setState({ errorMessage: 'Password Does not match', errorStatus: false });
            return undefined;
        }
        setPassword({ password: data.password }).then((response) => {
            this.setState({ errorMessage: response.message, errorStatus: response.status });
            window.location.href = '/login';
            // this.props.history.push('/login');
        }).catch(error => {
            console.log(error, 'error');
        });
    };

    onBackToPage = () => {
        this.setState({ otpField: false });
    }

    onChange = (e) => {
        const { data } = this.state;
        data[e.target.name] = e.target.value;
        this.setState({ data, errorMessage: false });
    }

    toggleIconChange = (type) => {
        let { passwordType, iconClassName, confirmPasswrdIcon, confirmPasswordType } = this.state;
        if (type === 'password') {
            passwordType = passwordType === 'password' ? 'text' : 'password';
            iconClassName = iconClassName === 'far fa-eye-slash' ? 'far fa-eye' : 'far fa-eye-slash';
        } else {
            confirmPasswordType = confirmPasswordType === 'password' ? 'text' : 'password';
            confirmPasswrdIcon = confirmPasswrdIcon === 'far fa-eye-slash' ? 'far fa-eye' : 'far fa-eye-slash';
        }
        this.setState({ passwordType, iconClassName, confirmPasswrdIcon, confirmPasswordType });
    }

    render() {
        const { userName, otpField, setPassword, errorMessage, errorStatus, sendOtpBtn, iconClassName, confirmPasswrdIcon, passwordType, confirmPasswordType, data: { phone, otp_token, password, confirm_password } } = this.state;
        const { onChange, onSetPassword, onSendOTP, onVerifyOTP, toggleIconChange, onBackToPage } = this;
        return (
            <div className="login-page" >
                <div className="login-box">
                    <RightSide />
                    <from
                        id="login-form"
                        initialValues={{ remember: true }}
                        style={{ paddingTop: '100px' }}
                    >
                        {setPassword
                            ? <>
                                <h2>{`Hi ${userName}`}</h2>
                                <p>Please set a password to continue.</p>
                                <input id='login-form_username' type={passwordType} value={password} name='password' placeholder="Password" onChange={(e) => onChange(e)} />
                                <i className={iconClassName} id="togglePassword" style={{
                                    marginLeft: '-30px', cursor: 'pointer'
                                }} onClick={() => { toggleIconChange('password'); }} />
                                <input id='login-form_username' type={confirmPasswordType} value={confirm_password} name='confirm_password' onKeyPress={(e) => { if (e.which === 13) onSetPassword(); }} placeholder="Confirm Password" onChange={(e) => onChange(e)} />
                                <i className={confirmPasswrdIcon} id="togglePassword" style={{
                                    marginLeft: '-30px', cursor: 'pointer'
                                }} onClick={() => { toggleIconChange(); }} />
                                {errorMessage && <div style={{ textAlign: 'center', color: errorStatus ? 'green' : 'red', padding: '5px' }}>{errorMessage} </div>}
                                <button type="primary" htmlType="submit" style={{ backgroundColor: '#0c5ef1' }} className="login-form-button" onClick={() => onSetPassword()}>
                                    Submit
                                </button>
                            </>
                            : <>
                                {otpField
                                    ? <>
                                        <p style={{ cursor: 'pointer' }} onClick={() => onBackToPage()}>Back</p>
                                        <p>Please enter the OTP sent to your mobile.</p>
                                        {/* <div className='otpform' > */}
                                        <input id='login-form_username' onKeyPress={(e) => { if (e.which === 13) onVerifyOTP(); }} maxLength="4" style={{ letterSpacing: '20px', fontSize: '30px', textAlign: 'center' }} type="text" name='otp_token' value={otp_token} onChange={(e) => onChange(e)} />
                                        {errorMessage && <div style={{ textAlign: 'center', color: errorStatus ? 'green' : 'red', padding: '5px' }}>{errorMessage} </div>}
                                        <button type="primary" htmlType="submit" style={{ backgroundColor: '#0c5ef1' }} className="login-form-button" onClick={() => onVerifyOTP()}>
                                            Submit
                                        </button>
                                    </>
                                    : <>
                                        <h1 className="form-title">Hi there,</h1>
                                        <p>Enter your GSM number to continue</p>
                                        <input id='login-form_username' value={phone} name='phone' placeholder="GSM Number"
                                            onKeyPress={(e) => { if (e.which === 13) onSendOTP(); }}
                                            onChange={(e) => onChange(e)} />
                                        {errorMessage && <div style={{ textAlign: 'center', color: errorStatus ? 'green' : 'red', padding: '5px' }}>{errorMessage} </div>}
                                        <button type="primary" disabled={sendOtpBtn} htmlType="submit" style={{ backgroundColor: '#0c5ef1' }} className="login-form-button" onClick={() => onSendOTP()}>
                                            Send OTP
                                        </button>
                                    </>
                                }
                            </>
                        }

                    </from>
                </div>
            </div>
        );
    }
};
export default SignUp;
