import React from 'react';
const onClick = (url) => {
    window.location.href = url;
};

export const ButtonStyle = (data) => {
    return (
        <div onClick={() => onClick(data.url)} className={`border-t-2 border-[#c7545e] text-[#c7545e] hover:cursor-pointer hover:bg-[#D42234] hover:text-[#fff] hover:border-[#D42234] botton_hover duration-150 ${data.style}`} >
            <button className="uppercase p-2 btn_move duration-150" >{data.title}</button>
        </div>
    );
};
