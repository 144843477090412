import React, { Component } from 'react';
import Header from 'components/navBar';
import CelebratingPeople from './celebrating_people';
import Banner from './banner';
import HrAnnouncement from './hr_announcement';
import Footer from 'components/Footer';
import ArticleOfMonth from './article_of_month';
import { Loader } from 'components/loader';
import { NewsEvents } from './news_events';
import RecentEvents from './recent_events';
import Notification from 'components/Notifications/Notification.js';
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 1,
            eventData: [],
            loader: true,
            firebaseTokenCall: true
        };
    }

    componentDidMount() {
        document.title = 'Tawasul - Home';
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.setState({
            firebaseTokenCall: false
        });
        setTimeout(this.handleTimer, 3000);
    }

    handleTimer = () => {
        this.setState({ loader: false });
    }
    render() {
        const { loader, firebaseTokenCall } = this.state;
        return (
            <React.Fragment>
                {firebaseTokenCall && <Notification />}
                {loader && <Loader />}
                <Header loader={loader} />
                <Banner />
                <RecentEvents />
                <CelebratingPeople {...this.props} />
                <ArticleOfMonth {...this.props} />
                <HrAnnouncement {...this.props} />
                <NewsEvents {...this.props} />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Home;
