import React, { useEffect, useState } from 'react';
import { fetchArticleByType } from 'api/article';
import { ButtonStyle } from 'components/button';

export const OpenPosition = (props) => {
    const [errorPage, setErrorPage] = useState(true);
    const [openPosition, setOpenPosition] = useState([]);
    const requestParams = [
        { name: 'open_position', value: 'open_position' }
    ];

    useEffect(() => {
        fetchArticleByType(requestParams, false, 6).then(({ data }) => {
            setOpenPosition(data);
            setErrorPage(false);
        }).catch(() =>
            setErrorPage(true)
        );
    }, []);

    if (errorPage) {
        return <div></div>;
    }
    return (
        <>
            {openPosition?.[0]?.id &&
                <div className="w-full bg-white" id="positions">
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 xl:px-4 mx-auto">
                        <div className="flex justify-between xs:flex-col xs:gap-5 sm:flex-row sm:container mx-auto z-[99999] py-[3rem] text-black">
                            <div className="xs:w-full sm:w-1/3 font-light">
                                <p className="sm:text-[1rem] md:text-xl lg:text-[1.3rem] font-medium uppercase">Open Position</p> </div>
                            <div className="xs:w-full lg:w-[36%] xl:w-1/3 items-center sm:gap-[10px] lg:gap-[10px] font-light sm:text-[1rem] md:text-[1.3rem] lg:text-xl hover:underline hover:cursor-pointer xs:text-left text-right">
                                <p
                                    onClick={() => props.history.push({
                                        pathname: `/category/${requestParams[0].name}`,
                                        state: { fetchValues: requestParams }
                                    })}
                                >
                                    See All <span className="uppercase">open Position</span>
                                </p></div>
                        </div>
                    </div>
                    <div className="sm:container xs:px-4 sm:px-4 md:px-4 lg:px-4 mx-auto flex flex-row flex-wrap xs:gap-2 sm:gap-2 lg:gap-3 xl:gap-4 2xl:gap-5 pb-10">
                        {openPosition?.map((position, j) => {
                            return (
                                <div key={j} className="xs:flex-[0_0_100%] sm:flex-[0_0_49%] md:flex-[0_0_49%] lg:flex-[0_0_32.33333%] xl:flex-[0_0_32.33333%] 2xl:flex-[0_0_32.33333%] p-10 flex flex-col gap-10 relative rounded-lg border-2">
                                    <div className='sm:h-[220px] flex flex-col gap-5 overflow-hidden'>
                                        <div className="text-center">
                                            <h2 className="sm:text-xl lg:text-3xl text-[#F6444C]">{position?.title}</h2>
                                        </div>
                                        <div className='text-justify' dangerouslySetInnerHTML={{ __html: position?.short_description }} />
                                    </div>
                                    <ButtonStyle title={'learn more'} style={'border-[#DF5E6B] text-[#DF5E6B] '} url={`/articles/${position?.alias_name}`} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="pb-10 hover:underline text-black">
                        {/* <p className="text-center text-[1.1rem]">See more <span className="uppercase">promotions & new recruits</span></p> */}
                    </div>
                </div>
            }
        </>
    );
};
